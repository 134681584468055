import * as React from "react";
import Text from "../../../component/form/text";
import { withRouter, Redirect } from "react-router-dom";
import {getAccess} from '../../access';
import {items} from '../../../page/list/component/specialists/sections';
import { AccountService } from '../../service';
import "./style.css";
import Loader from '../../../component/layout/loader';

class NotFound extends React.PureComponent {
    render() {
        return (
            <div className="text_center_check">
               <Loader size={100} />
            </div>
        );
    }
}

export default withRouter(NotFound);
