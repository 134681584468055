import { AccountService } from './service';
import { store } from '../store/create';
import sections from '../page/list/component/specialists/sections';

export function getAccess(type, id, event) {
	const state = store.getState();
	if (state.config.user_role === 'staff') {
		const series = state.config.series ? state.config.series : [];
		if (type === 'group') {
			const group = series.filter(x => x.id === id).length !== 0
				? series.filter(x => x.id === id)[0]
				: [];

			return group.length !== 0 ? group.enabled : false;
		} else {
			const sections = state.config.sections ? state.config.sections : [];
			const section = sections.find(x => x.id === id);
			return section ? section[event] : false;
		}
	} else {
		return true;
	}
}

const sspro_admin_ids = JSON.parse(process.env.REACT_APP_SSPRO_ADMINS_ORG_IDS);
const staff_exeptions = ['spec',];
const handleExeptions = (obj, exeptions) => {
	let filteredObj = {};
	Object.keys(obj).forEach(key => {
		if (!exeptions.includes(key)) filteredObj[key] = obj[key];
	});
	return filteredObj;
}
export const getRoutesByRole = async (routes, routesByRoles, userRole, orgId) => {
	let modules = null;
	let filteredRoutes = [];

	if (!sspro_admin_ids.includes(Number(orgId)) && userRole !== 'trustee') {
		if (!modules) {
			try {
				modules = await AccountService.getItem({ id: `${orgId}/modules` })
				if(userRole === 'staff') modules = handleExeptions(modules, staff_exeptions);
			} catch (error) {
				modules = null;
			}
		}
		if (modules){
			filteredRoutes = routes.filter(route => modules[route.name] || routesByRoles[route.link] )
		}
		if (userRole === 'staff') {
			filteredRoutes = filteredRoutes.map(route => {
				return { ...route, showInMenu: modules[route.name]
						? route.showInMenu
						: routesByRoles[route.link] ? route.showInMenu : false 
					}
			})
			filteredRoutes = filteredRoutes.filter(route => {
				if (route.link === '/check') return true;
				let section = sections.find(section => section.name === route.name);
				return section && getAccess('sections', section.id, 'read');
			})
	
			return filteredRoutes;
		}
	}
	if (modules && filteredRoutes.length) {
		return filteredRoutes;
	}
	return routes.filter(route => routesByRoles[route.link]).map(route => ({ ...route, showInMenu: routesByRoles[route.link].show_in_menu }))

}