import 'babel-polyfill';

import React from 'react'
import ReactDOM from 'react-dom'
import 'normalize.css/normalize.css'
import './index.css'
import './buttonCreate.css'
import App from './App'
import { positions, Provider } from "react-alert";
import { Provider as StoreProvider } from 'react-redux';
import { store } from './store/create'
import AlertTemplate from "react-alert-template-basic";
import initAnalytics from './analytics';

const alert_options = {
  timeout: 5000,
  position: positions.BOTTOM_RIGHT
};

initAnalytics();
ReactDOM.render(
  <StoreProvider store={store}>
    <Provider template={AlertTemplate} {...alert_options}>
      <App />
    </Provider>
  </StoreProvider>, 
  document.getElementById('root'))

