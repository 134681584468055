// @flow
import * as React from 'react'
import TextField from '@material-ui/core/TextField'

type Props = {
  name: string;
  error?: string;
  label: string;
  value?: string;
  onChange?: (name: string, value: string) => void;
  multiline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const FormTextField = ({ name, label, error, value, onChange, multiline, fullWidth = true, disabled }: Props) => (
  <TextField
    multiline={multiline}
    fullWidth={fullWidth}
    error={Boolean(error)}
    name={name}
    label={label}
    value={value || ''}
    onChange={(event: Object) => onChange && onChange(event.target.name, event.target.value)}
    helperText={error}
    rows={multiline && 2}
    disabled={disabled}
  />
)

export default FormTextField
