import React from 'react';
import styles from './loginStyle';
import Footer from './layout/Footer/footer';
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import ModalRecovery from './modal';
import { UserConfigService } from '../config/service';

import butterfly from '../asset/pictogram/butterfly.png';
import chat from '../asset/pictogram/chat.png';
import heart from '../asset/pictogram/heart.png';
import network from '../asset/pictogram/network.png';

import logo from '../asset/logo.png';
import {deleteQueryParamsValue, getQueryParamsValue} from "../service/helpers";

const pictograms = {
    butterfly: butterfly,
    chat: chat,
    heart: heart,
    network: network
}
const CAPTION = `ИННОВАЦИОННАЯ
ОБРАЗОВАТЕЛЬНАЯ ПЛАТФОРМА`;
const CAPTIONHMAO = `ЦИФРОВОЙ ДЕТСКИЙ САД ЮГРЫ`

const recoveryStatus = {
  successful: 'Пароль сброшен. Новый пароль отправлен на почту',
  token_expired: 'Ссылка  для сброса пароля не действительна',
  password_already_changed: 'По этой ссылку сброс пароля ранее был произведен',
}



class Pictogram extends React.Component {
    render() {
        const { caption, src } = this.props;
        return (
            <div style={styles.pictogram}>
                <div style={styles.pictogramCaption}>{caption}</div>
                <div style={styles.pictogramImgContainer}>
                    <img alt="" style={styles.pictogramImg} src={pictograms[src]} />
                </div>
            </div>
        );
    }
}

class Recovery extends React.Component {

    componentDidMount() {
        const {history} = this.props
        const {search} = history.location
        const status = getQueryParamsValue(search, 'status')

        const email = localStorage.getItem('login');
        document.querySelector('#email').value = email;

        history.push({search: deleteQueryParamsValue(search, 'status')})

        if (status) {
            this.handlerPick(recoveryStatus[status])
        }
    }

    state = {
        values: {
            email: '',
            username: localStorage.getItem('login')
        },
        errors: {},
        modalLogic: '',
        modalText: '',
        isHmaoSite: process.env.REACT_APP_API_BASE_URL.includes('admhmao')
    }

    handlerCloseModal = () => {
        this.setState(() => ({
            modalLogic: ''
        }));

        localStorage.removeItem('login');
        localStorage.removeItem("orgID");
        localStorage.removeItem("real_orgID");

        this.props.history.push('/login')
    }

    handlerPick = (msg) => {
        this.setState(() => ({
            modalLogic: msg
        }));
    }

    onSubmit = (event: Object) => {
        event.preventDefault()

        UserConfigService.putItem({
            data: {email: this.state.values.username},
            id:''
        }).then(response => {
            if (response.success) {
                this.handlerPick('На адрес электронной почты, указанный при регистрации, выслана инструкция по восстановлению пароля');
            } else {
                this.handlerPick(response.messages[0]);
            }

            setTimeout(this.handlerCloseModal, 3000);
        }).catch(err => {
            if (err.response.status === 400) {
                this.handlerPick('Ваш аккаунт не активирован. Подождите немного или обратитесь к администратору')
                setTimeout(this.handlerCloseModal, 3000)
            }
        })
    }

    onChange = (name: string, value: string) => {
        const { values } = this.state

        this.setState({
            values: {
            ...values,
            [name]: value,
            }
        })
    }

    render() {

        return (
            <div style={styles.authContainer}>
                <header style={styles.header}>
                    <div style={styles.headerContainer}>
                        <img alt="" style={styles.logo} src={logo}></img>
                        <span style={styles.caption}>
                            {this.state.isHmaoSite ? CAPTIONHMAO : CAPTION}
                        </span>
                    </div>
                </header>
                <div style={styles.body}>
                    <div style={styles.leftSide}></div>
                    <form style={styles.form} onSubmit={this.onSubmit}>
                        <div style={styles.offset}></div>
                        <div style={styles.welcome}>Восстановление пароля</div>
                        <div>
                            <p style={styles.description}>
                                Введите ваш адрес электронной почты, указанный при регистрации
                            </p>
                        </div>
                        <div style={styles.loginPassword}>
                            <div style={styles.label}>
                                <label fromhtml="email">Email</label>
                            </div>
                            <div>
                                <input id="email" type="textbox" style={styles.input} onChange={(event) => this.onChange('username', event.target.value)}></input>
                                <input style={styles.submit} type="submit" value="ок"></input>
                            </div>
                        </div>
                        <Link onClick={this.localEmail} to="/login" style={styles.restore}>
                            Вернуться на страницу ввода логина
                        </Link>
                    </form>
                    <div style={styles.rightSide}></div>
                </div>
                <div style={styles.pictograms}>
                    <div style={styles.pictogramsContainer}>

                    </div>
                </div>
                <div style={styles.footer}>
                    <Footer />
                </div>
                <ModalRecovery
                    modalLogic={this.state.modalLogic}
                    modalText={this.state.modalText}
                    handlerCloseModal={this.handlerCloseModal}
                />
            </div>
        );
    }
}

export default withRouter(Recovery);
