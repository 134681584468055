// @flow
import * as React from 'react'

type Props = {
  value: string;
  style?: Object;
};

class Text extends React.Component<Props> {
  render() {
    const { value, style } = this.props
    
    return (
      <div style={style}>{value}</div>
    )
  }
}

export default Text
