// @flow
import * as React from 'react'

type Props = {
  list: Array<*>;
  children: (item: *) => *;
};

const List = ({ list = [], children }: Props) => list.map((item: *, index: number) => (
  <div key={index}>
    {children(item)}
  </div>
))

export default List
