// состояния порядка для списков в редакторе
export const order_states = {
    default: 'default', 
    reverse: 'reverse'
}
// состояния компонента редактора
export const mode_states = {
    default: 'default',
    editing: 'editing',
    adding: 'adding'
}

export const CACHE_NAME = 'account-auth-token'
