// @flow
import { createStore, applyMiddleware, compose } from 'redux';
import reduceReducers from 'reduce-reducers';
import thunk from 'redux-thunk';
import { reducer, middleware } from 'constructor';
import localeReducer from './reducers/';
import { CACHE_NAME } from '../constants/common';
//const CACHE_NAME = process.env.REACT_APP_AUTH_CACHE_NAME

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const reducers = (state, action) => {
  const initAuthState = {
    auth: {
      isLoading: false,
      data: { user: localStorage.getItem(CACHE_NAME) ? {} : null },
      error: null,
      errorText: '',
      reasonBlock: '',
    },
    notification: {},
    config: {
      org_id: null,
      user_role: null,
      user_id: null,
      sections: [],
      series: [],
    },
    profile: {
      profile_id: null,
      profile_name: null,
    }
  }
  const { auth, notification, ...locale} = state ? state : initAuthState;

  return {
    ...reducer({auth: auth, notification: notification}, action),
    ...localeReducer(locale, action)
  }
}
export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk, ...middleware)
  )
)
