// @flow

import { type AxiosResponse, type AxiosError, type AxiosInstance } from 'axios'
import { type Request } from '../types'

const delay = () => new Promise(resolve => setTimeout(() => resolve(), 1000))
const requestDelay = 2000

const requestList = {}

const initResponse = (instance, key, dataSource, params) => {
  return Promise.all([instance(dataSource, params), delay()])
    .then((response: [AxiosResponse, void]) => {
      requestList[key] = { ...requestList[key], response: response[0].data }
      return response[0].data
    })
    .catch((err) => {
      console.log('err', err) // eslint-disable-line
      throw err
    })
}

const fetcherGet = (instance: AxiosInstance) => (dataSource: string, params?: *) => {
  const paramsKey = Object.entries(params.params || {}).filter(([key, value]) =>  value !== null)
  params.params = Object.fromEntries(paramsKey)
  const key = JSON.stringify(`url: ${ dataSource }`) + JSON.stringify(params)

  if (Object.keys(requestList).includes(key) &&
    (Date.now() - requestList[key].timeResponse < requestDelay)) {
    return new Promise((resolve) => {
      let count = 20
      const timerInterval = setInterval(() => {
        if (requestList[key].response && count ) {
          resolve(requestList[key].response)
          clearInterval(timerInterval)
        }
        if (!count) {
          resolve(initResponse(instance, key, dataSource, params))
          clearInterval(timerInterval)
        }
        count--
      }, 500)
    })
  }
  requestList[key] = { ...requestList[key], timeResponse: Date.now() }
  return initResponse(instance, key, dataSource, params)
}

const fetcher = (instance: AxiosInstance) => (dataSource: string, params?: *) => {
  return Promise.all([instance(dataSource, params), delay()])
    .then((response: [AxiosResponse, void]) => response[0].data)
    .catch((err) => {
      console.log('err', err) // eslint-disable-line
      throw err
    })
}

type Data = {
  id?: string;
  data?: any;
}

const fetchAction = (request: Request, data?: Data) => {
  return request.action(data)
    .then((response) => {
      if (request.onSuccess) {
        request.onSuccess(response, data)
      }

      return response
    }, (error: AxiosError) => {
      if (request.onError) {
        request.onError(error, data)
      }

      throw error
    })
}

export {
  fetcher,
  fetchAction,
  fetcherGet,
}
