import { profiles } from '../config/page/profiles/constants'

export const setProfileNameToLocalStorage = (id) => {
  localStorage.setItem('profileName', profiles[id])
}

export const removeProfileNameFromLocalStorage = () => {
  localStorage.removeItem('profileName')
}

const getProfileById = (id) => profiles.find(profile => profile.id === id)

export const getProfileName = (id) => getProfileById(id).name
export const getProfileTitle = (id) => getProfileById(id).title
export const getProfileShortTitle = (id) => getProfileById(id).shortTitle

export const setHeaders = (profileId, services) => {
  if (profileId) {
    const profileName = getProfileName(profileId)
    services.forEach(service => service.setHeaders(profileName))
  }
}

export const clearHeaders = (services) => {
  services.forEach(service => service.removeHeaders())
}
