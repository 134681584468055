export const config = {
        org_id: null,
        user_role: null,
        user_id: null,
        sections: [],
        series: []
}

export const profile = {
    profile_id: null,
    profile_name: null
}

export default {
    locale: {
        config,
        profile
    }
}