// @flow
import * as React from 'react'
import { compose } from 'redux'
import { auth } from 'constructor'
import LoginForm from '../component/login-form'
import { getQueryParamsValue } from '../service/helpers'

type Props = {
  auth: any;
  authActions: {
    login: (payload: {
      username: string;
      password: string;
    }) => Promise<void>;
  }
}

type State = {
  values: {
    username: string;
    password: string;
  };
  errors: {
    [key: string]: string;
  };
}

const usernameParams = getQueryParamsValue(location.search, 'username')
const passwordParams = getQueryParamsValue(location.search, 'password')

class AuthLoginFormContainer extends React.Component<Props, State> {
  state = {
    values: {
      username: usernameParams || '',
      password: passwordParams || '',
    },
    errors: {},
  }

   submit = (event: Object) => {

    const { authActions, auth } = this.props
    const { values } = this.state
    localStorage.clear()
    event.preventDefault();
    authActions.login(values)
  }

  onChange = (name: string, value: string) => {
    const { values } = this.state

    this.setState({
      values: {
        ...values,
        [name]: value,
      }
    })

    setTimeout(()  => {this.props.auth.error = null}, 3000);
  }

  render() {
    const { auth, isAuth } = this.props
    const { values, errors } = this.state

    if (auth.isLoading) {
      return <div style={{marginLeft: 10, marginTop: 10}}>Загрузка...</div>
    }

    return (
      <LoginForm
        isAuth={isAuth}
        onSubmit={this.submit}
        onChange={this.onChange}
        values={values}
        errors={errors}
        auth={auth}
      />
    )
  }
}

export default compose(
  auth.withAuth,
)(AuthLoginFormContainer)
