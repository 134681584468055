// @flow
import * as React from 'react'

import List from './module/widget/component/list'
import Button from './module/widget/component/button'
import Obj from './module/widget/component/object'
import Text from './module/widget/component/text'
import Form from './module/widget/component/form'

import FormInput from './module/widget/component/form/input'
import FormSelect from './module/widget/component/form/select'
import FormToggle from './module/widget/component/form/toggle'

import validation from './service/validation'

// Widgets
export type Widget =
  List |
  Button |
  Obj |
  Text |
  Form |
    FormInput |
    FormSelect |
    FormToggle

// END______________________

export type Page = {
  title: string;
  showInMenu: boolean;
  link: string;
  component: React.ComponentType<{
    history: Object;
    location: Object;
    match: Object;
  }>;
}

export type Notification = {
  message: string;
}

export type Request = {
  action: (...args: any) => Promise<*>;
  onSuccess?: (response: any, data?: any) => any;
  onError?: (message: string, data?: any) => any;
}

export type ObjScheme = {
  [key: string]: React.ComponentType<{
    value: any;
    context: any;
  } & *>;
}

export type FormScheme = {
  [key: string]: {|
    label: string;
    validation?: Validation;
    component: React.ComponentType<{
      name: string;
      onChange: (name: string, value: any) => void;
      label: string;
      error?: string;
      value: any;
      context: any;
    } & *>;
  |}
}

// Normalize data
export type Normalize = Array<(value: any) => any>

// Template
export type TemplateItem = {
  size: {
    lg: number;
    md?: number;
    sm?: number;
  }
}

export type Template = Array<Array<TemplateItem>>;
export type MappedSchemeToTemplate = Array<Array<TemplateItem & {
  name: string;
  Component: React.ComponentType<*>;
}>>;

export type Validation = Array<$Keys<typeof validation>>;
