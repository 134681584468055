// @flow
import uniqid from 'uniqid'

// Actions
export const SET = 'notification/SET'
export const REMOVE = 'notification/REMOVE'

export type Message = {
  message: string;
  duration: number;
  level: 'error' | 'info' | 'success';
}

export type State = {
  [id: string]: Message;
}

export type Set = {
  type: typeof SET;
  payload: {
    message: Message;
  }
}

export type Remove = {
  type: typeof REMOVE;
  payload: {
    id: string;
  }
}

export type Action = Set | Remove

// Reducer
export default function reducer(state: State = {}, action: Action): State {
  switch (action.type) {
    case SET:
      return {
        ...state,
        [uniqid()]: action.payload.message,
      }

    case REMOVE:
      return Object
        .keys(state)
        .filter(id => (
          action.payload.id &&
          id !== action.payload.id
        ))
        .reduce((acc, id) => ({
          ...acc,
          [id]: state[id],
        }), {})

    default: return state
  }
}

export const set = (message: Message) => ({
  type: SET,
  payload: {
    message,
  }
})

export const remove = (id: string) => ({
  type: REMOVE,
  payload: {
    id,
  }
})
