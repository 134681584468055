// @flow
import {
  LOGIN_SUCCESS,
  REFRESH_LOGIN_SUCCESS,
  GET_CACHED_DATA_SUCCESS,
  LOGIN_ERROR,
  REFRESH_LOGIN_ERROR,
  GET_CACHED_DATA_ERROR,
  LOGOUT_SUCCESS,

  instance,

  type Action,
} from './duck'
import { save, clear } from './cache'

export const saveAuthData = () => (next: *) => (action: Action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case REFRESH_LOGIN_SUCCESS:
    case GET_CACHED_DATA_SUCCESS:
      instance.defaults.headers.common['Authorization'] = `Bearer ${action.payload.access_token}`
      save(action.payload)
      break

    default: break
  }

  return next(action)
}

export const removeAuthData = () => (next: *) => (action: Action) => {
  switch (action.type) {
    case LOGIN_ERROR:
    case REFRESH_LOGIN_ERROR:
    case GET_CACHED_DATA_ERROR:
    case LOGOUT_SUCCESS:
      delete instance.defaults.headers.common['Authorization']
      clear()
      break

    default: break
  }

  return next(action)
}
