export const monitoring = {
    ages: () => 'ages/',
    program: () => 'program/',
    programs: () => 'programs/',
    program_generate: () => 'monitoring/generate-program/',
    education_areas: (id) => `program/${ id }/education_area/`,
    activity_types: (id) => `program/${ id }/activity_type/`,
    skills: (id) => `program/${ id }/skill/`,
    defaults: () => `defaults/`,
    monitoring: () => `monitoring/`,
    monitoring_table: (id) => `monitoring/get-table/${ id }/group/`,
    started_monitoring: () => 'started_monitoring/',
    started_monitoring_groups: (id) => `started_monitoring/groups/`,
    started_monitoring_children: (id) => `started_monitoring/children/`,
    started_monitoring_results: (id) => `started_monitoring/${ id }/results/`,
    started_monitoring_points: (id) => `started_monitoring/${ id }/result/`,
    group_attendance: (id) => `group/${ id }/attendance/`,
    group_mood: (id) => `group/${id}/mood/`,
    group_sleep: (id) => `group/${id}/sleep/`,
    group_bowel_movement: (id) => `group/${id}/bowel_movement/`,
    started_monitoring_results_diagram: (id) => `started_monitoring/${ id }/education_areas_stats/`,
    child_results_with_reports: (id) => `child-results-with-reports/${ id }/`,
}
export const reports = {
    group_attendance: (id) => `attendance/`,
    group_attendance_table: (id) => `attendance-table/`,
    group_mood: (id) => `mood/`,
    group_mood_table: (id) => `mood-table/`,
    group_sleep: (id) => `sleep/`,
    group_sleep_table: (id) => `sleep-table/`,
    group_bowel_movement: (id) => `bowel-movement/`,
    group_bowel_movement_table: (id) => `bowel-movement-table/`,
    custom_field: (id) => `by-field-id/${id}`,
    custom_field_table: (id) => `by-field-id-table/${id}`,
}

export const composite = {
    monitoring_results_report: () => 'monitoring-results/report',
    monitoring_results_child_pdf: () => 'monitoring-results/report-child-pdf',
    monitoring_results_group_pdf: () => 'monitoring-results/report-group-pdf',
    monitoring_results_child_html: () => 'monitoring-results/report-child-html',
    monitoring_results_group_html: () => 'monitoring-results/report-group-html',
    monitoring_results_send_child_result: () => `monitoring-results/send-child-result`,

    schedule_group: () => 'groups/schedule'
}

export const food = {
    food: () => 'food/',
    food_report: () => 'food/report/',
    food_batch: () => 'food/batch/',
    food_hints: () => 'food/hints/'
}
