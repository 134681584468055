// @flow

export const required = (value: *): string => {
  const message = 'Поле обязательно для заполнения'

  if(!value && typeof value !== 'number') {
    return message
  }

  if(Array.isArray(value) && value.length === 0) {
    return message
  }

  return ''
}

export const min = (value: *): string => {
  const message = 'Минимальное количество символов — 3'

  if(typeof value === 'string' && value.length < 3) {
    return message
  }

  return ''
} 

export const phone = (value: string): string => {
  const message = 'Некорректный номер телефона (+7(XXX)XXX-XX-XX)'

  if(
    typeof value !== 'string' ||
    !value.match(/^(\+7|8)\(\d{3}\)\d{3}-\d{2}-\d{2}$/)
  ) {
    return message
  }

  return ''
}

export const email = (value: string): string => {
  const message = 'Некорректный email'

  if(
    typeof value === 'string' &&
    // eslint-disable-next-line
    !value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
    return message
  }

  return ''
}

export const text = (value: string): string => {
  const message = 'Разрешено вводить только текст'

  if(
    typeof value !== 'string' ||
    // eslint-disable-next-line
    !value.match(/^(?![\s.]+$)[а-яА-ЯёЁa-zA-Z\s.]*$/)) {
    return message
  }

  return ''
}

export const snils = (value: *): string => {
  if(typeof value === 'string' && value.length !== 11) {
    return 'СНИЛС должен состоять из 11 цифр'
  }

  return ''
}

const validation = {
  required,
  min,
  phone,
  email,
  text,
  snils,
}

export default validation
