// @flow
import axios, { type AxiosError, type AxiosResponse } from 'axios'
import qs from 'qs'
import { type Dispatch } from 'redux'
import { decode } from 'jsonwebtoken'
import { get as getDataFromCache } from './cache'

// Actions
export const LOGIN = 'auth/LOGIN'
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS'
export const LOGIN_ERROR = 'auth/LOGIN_ERROR'

export const REFRESH_LOGIN = 'auth/REFRESH_LOGIN'
export const REFRESH_LOGIN_SUCCESS = 'auth/REFRESH_LOGIN_SUCCESS'
export const REFRESH_LOGIN_ERROR = 'auth/REFRESH_LOGIN_ERROR'

export const GET_CACHED_DATA = 'auth/GET_CACHED_DATA'
export const GET_CACHED_DATA_SUCCESS = 'auth/GET_CACHED_DATA_SUCCESS'
export const GET_CACHED_DATA_ERROR = 'auth/GET_CACHED_DATA_ERROR'

export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS'
const CACHE_NAME = process.env.REACT_APP_AUTH_CACHE_NAME
export type LoginPayload = {
  username: string;
  password: string;
}

export type LoginResponse = {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: 'Bearer';
  ['not-before-policy']: string;
  session_state: string;
}

export type User = {
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

export type State = {
  isLoading: boolean;
  data: {
    user?: User | null;
  };
  error: any;
}

export type Login = {
  type: typeof LOGIN;
}

export type LoginSuccess = {
  type: typeof LOGIN_SUCCESS;
  payload: LoginResponse;
}

export type LoginError = {
  type: typeof LOGIN_ERROR;
  error: string;
}

export type Logout = {
  type: typeof LOGOUT_SUCCESS;
}

export type Action = Login | LoginSuccess | LoginError | Logout

// Auth API instance
export const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
})

const initialState: State = {
  isLoading: false,
  data: {},
  error: null,
  errorText: '',
  reasonBlock: '',
}

// Reducer
export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case LOGIN:
    case REFRESH_LOGIN:
      return {
        ...state,
      }

    case LOGIN_SUCCESS:
    case REFRESH_LOGIN_SUCCESS:
    case GET_CACHED_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          user: decode(action.payload.access_token)
        },
      }

    case LOGIN_ERROR:
    case REFRESH_LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        errorText: action.errorText,
        reasonBlock: action.reasonBlock,
      }

    case GET_CACHED_DATA_ERROR:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        data: {
          user: null,
        },
      }

    default: return state
  }
}

// Action creators and side effects
export const login = (payload: LoginPayload) => (dispatch: Dispatch) => {
  dispatch({ type: LOGIN })
  return instance.post('', qs.stringify({
    grant_type: 'password',
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    ...payload,
  }))
    .then((response: AxiosResponse<LoginResponse>) => {
      dispatch({ type: LOGIN_SUCCESS, payload: response.data })
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: LOGIN_ERROR,
        error: ( error.response && error.response.status ),
        errorText: ( error.response && error.response.data.error_description),
        reasonBlock: ( error.response && error.response.data.reason_block),
      })
    })
}

export const refreshLogin = (refresh_token: string) => (dispatch: Dispatch) => {
  dispatch({ type: REFRESH_LOGIN })
  return instance.post('', qs.stringify({
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    refresh_token,
  }))
    .then((response: AxiosResponse<LoginResponse>) => {
      dispatch({ type: REFRESH_LOGIN_SUCCESS, payload: response.data })
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: REFRESH_LOGIN_ERROR,
        error: ( error.response && error.response.status ),
        errorText: ( error.response && error.response.data.error_description),
        reasonBlock: ( error.response && error.response.data.reason_block),
      })
    })
}

export const getCachedData = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_CACHED_DATA })
  const cache = getDataFromCache()

  if (cache) {
    const timestamp =  Date.now() / 1000
    const decodedToken = decode(cache.access_token)

    if (
      decodedToken &&
      decodedToken.exp > timestamp
    ) {
      return dispatch({ type: GET_CACHED_DATA_SUCCESS, payload: cache })
    }

    const decodedRefreshToken = decode(cache.refresh_token)
    if (
      decodedRefreshToken &&
      decodedRefreshToken.exp > timestamp
    ) {
      return dispatch(refreshLogin(cache.refresh_token))
    }
  }

  localStorage.clear()
  return dispatch({ type: GET_CACHED_DATA_ERROR })
}

export const logout = () => ({ type: LOGOUT_SUCCESS })
