import { lazy } from "react"

const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem('lazy-retry-refreshed') || 'false')

        try {
            const component = await componentImport()
            window.sessionStorage.setItem('lazy-retry-refreshed', 'false')

            return component

        } catch(error) {
            if (!hasRefreshed) {
                window.sessionStorage.setItem('lazy-retry-refreshed', 'true')

                return window.location.reload()
            }

            throw error
        }
    })

export default lazyWithRetry
