import * as React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import './style.css';

class Button extends React.Component {
    static displayName = 'Button';

    static propTypes = {
        className: propTypes.string,
        style: propTypes.object,
        onClick: propTypes.func,
        submit: propTypes.bool,
        disabled: propTypes.bool,
        label: propTypes.string,
        primary: propTypes.bool,
        to: propTypes.string,
    }

    render() {
        const { className, style, onClick, submit, disabled, label, primary, to } = this.props;

        const props = {
            type: submit ? 'submit' : 'button',
            disabled,
            className: classnames(
              'custom-button',
              primary && 'custom-button__primary',
              disabled && 'custom-button__disabled',
              className
            ),
            style,
            onClick: !to ? onClick : null,
            value: label,
        };

        return !to
          ? <input {...props} />
          : <Link to={to}><input {...props} /></Link>
    }
}

export default Button;
