// @flow
import _ from 'lodash'

import validationRules from '../../../service/validation'
import {
  type Validation,
  type FormScheme,
} from '../../../types'

type Scheme = FormScheme | {
  [key: string]: {
    validation?: Validation;
  }
}

export type Rules = {
  [key: string]: Function;
}

export const validate = (
  scheme: Scheme = {},
  data: { [key: string]: any; },
  additionalRules?: Rules,
) => {
  return Object
    .keys(scheme)
    .reduce((acc, name) => {
      return {
        ...acc,
        [name]: validateField(
          _.get(scheme, `${name}.validation`),
          _.get(data, name),
          additionalRules,
        )
      }
    }, {})
}

export const validateField = (
  fieldValidationRules: Validation = [],
  value: *,
  additionalRules?: Rules = {},
) => { // TODO: optimise this function
  const noError = ''
  let error = noError

  for (let index = 0; index < fieldValidationRules.length; index++) {
    const rule = fieldValidationRules[index]
    error = ({
      ...validationRules,
      ...additionalRules,
    })[rule](value)

    if (error !== noError) {
      return error
    }
  }

  return error
}

export const isValidForm = (errors: { [key: string]: any; }) => {
  return Object.values(errors).every(error => error === '')
}
