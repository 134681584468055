import { RolesService } from '../config/service';
import { logout, refreshLogin } from 'constructor/lib/module/auth/duck';
import { store } from '../store/create';
import { CACHE_NAME } from '../constants/common';

//const CACHE_NAME = process.env.REACT_APP_AUTH_CACHE_NAME
const refreshToken = localStorage.getItem(CACHE_NAME) && JSON.parse(localStorage.getItem(CACHE_NAME)).refresh_token
const refreshLoginAction = (rToken) => store.dispatch(refreshLogin(rToken))
const logoutAction = () => store.dispatch(logout())

export const errorHandler = async (error, instance) => {
 const originalConfig = error.config;

    if (error.response) {

      if (originalConfig.url.includes('token') &&
        error.response.data['error_description'] === 'Session not active') {
        await logoutAction()
      }
      // Access Token was expired
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          refreshToken ? await refreshLoginAction(refreshToken) : await logoutAction()

          const token = localStorage.getItem(CACHE_NAME) && JSON.parse(localStorage.getItem(CACHE_NAME)).access_token
          originalConfig.headers.Authorization = token ? `Bearer ${token}` : ''
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(error);
}

export async function setInitData(data, setUserData) {
    const { auth, authActions } = data;
    const { user } = auth.data;
    const { user_role, org_id, series, sections } = data;
    if (authActions.login.length == 1 && user) { // if user logged in
        if (!localStorage.getItem('orgID') || !localStorage.getItem('roleUser') || !localStorage.getItem('userId')) {
            setStorageData(user);
            setUserData(getUserPayload(user))
            if (user.role_field === "staff") {
                const roles = await RolesService.getItem({ id: user.access })
                setStorageData(roles);
                setUserData(getUserPayload({ ...user, ...roles }));
            }
        }
        else if ((localStorage.getItem('orgID') &&
                localStorage.getItem('roleUser') &&
                localStorage.getItem('userId')) &&
                (!user_role || !org_id) ) {
                    setUserData(getUserPayload(user));
                    if (user.role_field === 'staff' && (!sections.length || !series.length)) {
                        const roles = localStorage.getItem('sections') && localStorage.getItem('series')
                        ? { sections: localStorage.getItem('sections'), series: localStorage.getItem('series') }
                        : await RolesService.getItem({ id: user.access })

                        setStorageData(roles);
                        setUserData(getUserPayload({ ...user, ...roles }));
                    }
                }
    } else if (!localStorage.getItem(CACHE_NAME)) {
        clearStorage();
    }
}

function clearStorage() {
    localStorage.removeItem("roleUser");
    localStorage.removeItem("orgID");
    localStorage.removeItem("userId");
    localStorage.removeItem('accountTitle');
    localStorage.removeItem('emailSspro');
    localStorage.removeItem('emailDou');
    localStorage.removeItem('avatarId');
   // localStorage.clear();
};
function setLSbyKey(value, key) {
    switch (key) {
        case 'org_id':
            return localStorage.setItem('orgID', value);
        case 'role_field':
            return localStorage.setItem('roleUser', value);
        case 'sub':
            return localStorage.setItem('userId', value);
        case 'series':
            return localStorage.setItem('series', value);
        case 'sections':
            return localStorage.setItem('sections', value);
    }
};
function setStorageData(data) {
    const dataKeys = Object.keys(data);
    dataKeys.forEach(key => setLSbyKey(data[key], key));
};
function getUserPayload(data) {
    const payload = {};
    const dataKeys = Object.keys(data);
    dataKeys.forEach(key => {
        switch (key) {
            case 'org_id':
                return payload['org_id'] = data[key];
            case 'role_field':
                return payload['user_role'] = data[key];
            case 'sub':
                return payload['user_id'] = data[key];
            case 'series':
                return payload['series'] = JSON.parse(data[key]);
            case 'sections':
                return payload['sections'] = JSON.parse(data[key]);
        }
    });
    return payload;
}
