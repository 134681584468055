import { SET_PROFILE, CLEAR_PROFILE } from '../actions';
import { profile } from '../initState';

export default (state = profile, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return {
                profile_id: action.payload.profile_id,
                profile_name: action.payload.profile_name
            };
        case CLEAR_PROFILE:
            return profile;
        default: 
            return state;
    }
}
