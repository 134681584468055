// @flow
import * as React from 'react'

type Props = {
  value: string;
  style?: Object;
}

const styles = {
  text: {
    fontSize: 16,
    lineHeight: 1.35,
    minHeight: '28px',
    display: 'flex',
    alignItems: 'center',
  }
}

const FormText = ({ value, style }: Props) => (
  <div style={{
    ...styles.text,
    ...style,
  }}>
    {value}
  </div>
)

export default FormText
