import TagManager from 'react-gtm-module'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import ym from 'react-yandex-metrika';

const tagManagerArgs = {
    gtmId: `GTM-${process.env.REACT_APP_GTM_ID}`
}
export const initYM = () => {
    if (process.env.NODE_ENV === 'production') {
        ym(Number(process.env.REACT_APP_YM_ID), "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
        });
    }
};
const initAnalytics = () => {
    if (process.env.NODE_ENV === 'production') TagManager.initialize(tagManagerArgs);
    
    if (process.env.REACT_APP_SENTRY_ENV === 'production') {
        Sentry.init({ 
          dsn: "http://aded5482e98472c97c40740b7842a1dc@192.168.100.4:9000/18",
          autoSessionTracking: true,
          integrations: [new Integrations.BrowserTracing()],
          environment: 'PROD',
          tracesSampleRate: 0.25,
          sampleRate: 0.2
        });
      } else if (process.env.REACT_APP_SENTRY_ENV === 'test'){
        Sentry.init({ 
          dsn: "http://aded5482e98472c97c40740b7842a1dc@192.168.100.4:9000/18",
          autoSessionTracking: true,
          integrations: [new Integrations.BrowserTracing()],
          environment: 'TEST',
          tracesSampleRate: 0.2,
          sampleRate: 0.2
        });
      }
};

export default initAnalytics;