// @flow
import * as React from 'react'
import _ from 'lodash'

import { validate, validateField, isValidForm, type Rules } from '../helper/form'
import { type Request, type Validation } from '../../../types'
import { fetchAction } from '../../../service/fetch'

type Props = {
  initialData?: { [key: string]: any; };
  scheme?: {
    [key: string]: {
      validation?: Validation;
    }
  };
  onSubmit: {
    request: Request;
    params?: Object;
  };
  onSyncValidationError?: Function;
  rules?: Rules;
  resetOnSubmit?: boolean;
}

type State = {
  data: { [key: string]: any; };
  error: { [key: string]: any; };
  isLoading: boolean;
}

const withForm = (WrappedComponent: *) => {
  class Form extends React.Component<Props, State> {
    mounted: boolean = false
    state = {
      error: {},
      data: this.props.initialData || {},
      isLoading: false,
    }
  
    componentDidMount() {
      this.mounted = true
    }
  
    componentWillUnmount() {
      this.mounted = false
    }
    
    onChange = (name: string, value: any) => {
      const { scheme, rules } = this.props
      const { data, error } = this.state
  
      this.setState({
        data: {
          ...data,
          [name]: value,
        },
        error: {
          ...error,
          [name]: validateField(
            _.get(scheme, `${name}.validation`),
            value,
            rules,
          ),
        },
      })
    }

    onDataChange = (incomingData: { [key: string]: any } = {}) => {
      const { scheme, rules } = this.props
      const { data, error } = this.state

      const newData = {
        ...data,
        ...incomingData,
      }

      const updatedErrors = {
        ...error,
        ...validate(scheme, newData, rules),
      }
  
      this.setState({
        data: newData,
        error: updatedErrors,
      })
    }

    onResetData = () => this.onDataChange(this.props.initialData)
  
    validate = () => {
      const { scheme, rules } = this.props
      const { error, data } = this.state

      const updatedErrors = {
        ...error,
        ...validate(scheme, data, rules),
      }
  
      this.setState({ error: updatedErrors })
  
      return {
        isValid: isValidForm(updatedErrors),
        errors: updatedErrors,
      }
    }
  
    onSubmit = (event: Object): Promise<any> => {
      const { onSubmit, onSyncValidationError, resetOnSubmit } = this.props
      const { data } = this.state
  
      event.preventDefault()
      const { isValid, errors } = this.validate()
  
      if (isValid) {
        this.setState({ isLoading: true, error: {} })
        return fetchAction(onSubmit.request, {
          ...onSubmit.params,
          data,
        })
          .then(() => {
            if (this.mounted) {
              this.setState({
                isLoading: false,
                data: resetOnSubmit ? {} : data,
              })
            }
          })
          .catch(() => {
            if (this.mounted) {
              this.setState({ isLoading: false })
            }
          })
      } else {
        if (onSyncValidationError) {
          return onSyncValidationError(errors)
        }

        return Promise.resolve()
      }
    }

    render() {
      const { scheme, ...rest } = this.props
      const { isLoading, data, error } = this.state
  
      return (
        <WrappedComponent
          {...rest}
          scheme={scheme}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          onDataChange={this.onDataChange}
          onResetData={this.onResetData}
          isLoading={isLoading}
          data={data}
          error={error}
        />
      )
    }
  }

  return Form
}

export default withForm
