// @flow
import * as React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import _ from 'lodash'

import {
  type Template,
  type ObjScheme,
} from '../../../types'
import { mappedSchemeToTemplate } from '../selector/object'


type Props = {
  data: {
    [key: string]: any;
  };
  schemeItemProps?:
    Object |
    (name: string) => Object;
  template: Template;
  scheme: ObjScheme;
};

class Obj extends React.Component<Props> {
  itemProps = (name: string) => {
    const { schemeItemProps } = this.props

    if (!schemeItemProps) {
      return {}
    }

    if (typeof schemeItemProps === 'object') {
      return schemeItemProps
    } else {
      return schemeItemProps(name)
    }
  }
  render() {
    const { data, template, scheme } = this.props

    return (
      <Grid fluid>
        {mappedSchemeToTemplate({ template, scheme })
          .map((row, ri) => (
            <Row key={ri}>
              {row.map((col, ci) => (
                <Col key={ci} {...col.size}>
                  <col.Component
                    value={_.get(data, col.name)}
                    context={data}
                    {...this.itemProps(col.name)}
                  />
                </Col>
              ))}
            </Row>
          ))}
      </Grid>
    )
  }
}

export default Obj

// {item ? item({
//   Component: col.Component,
//   name: col.name,
//   data: _.get(data, col.name),
// }) : (
//   <col.Component
//     name={col.name}
//     data={_.get(data, col.name)}
//   />
// )}
