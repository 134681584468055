export default {
    "/check": {
        show_in_menu: false,
        render_in_route: true
    },
    "/children": {
        show_in_menu: true,
        render_in_route: true
    },
    "/children/:id/dashboard": {
        show_in_menu: false,
        render_in_route: true
    },
    "/children/:id/card/:page/:tab": {
        show_in_menu: false,
        render_in_route: true
    },
    "/contracts": {
        show_in_menu: true,
        render_in_route: true
    },
    "/contracts/:id": {
        show_in_menu: false,
        render_in_route: true
    }
}