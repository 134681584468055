// @flow
import * as React from 'react'
import { compose } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'
import { auth } from 'constructor'

type Props = {
  auth: {
    data: {
      user?: Object;
    }
  };
  location: Object;
}


const withRedirect = (WrappedComponent: *) => {

  class Red extends React.Component<Props & *> {
    startRoute: string = '/contracts'
    startParentRoute: string = '/children'
    startAdminRoute: string = '/accounts'
    loginRoute: string = '/login'
    recoveryRoute: string = '/recovery'
    staffRoute: string = '/check'

    render() {
      const { auth, location, ...rest } = this.props;
      if (auth.data.user && location.pathname === this.loginRoute) {
        if (auth.data.user.role_field === 'super_admin') { //Администратор системы
          return (
            <Redirect to={this.startAdminRoute} />
          )
        } 

        if (auth.data.user.role_field === 'user') { //Локальный админ сада
          return (
            <Redirect to={this.startRoute} />
          )
        } 

        if (auth.data.user.role_field === 'staff') { //Локальный сотрудник сада
          return (
            <Redirect to={this.staffRoute} />
          )
        } 

        if (auth.data.user.role_field === 'admin') { //Сотрудники локального сада
          return (
            <Redirect to={this.startRoute} />
          )
        } 

        if (auth.data.user.role_field === 'trustee') { //Родители
          return (
            <Redirect to={this.startParentRoute} />
          )
        } 
      }

      if ( auth.data.user === null &&
        location.pathname !== this.loginRoute && location.pathname !== this.recoveryRoute
      ) {
        return (
          <Redirect to={this.loginRoute} />
        )
      } 

      return <WrappedComponent {...rest} />
    }
  }

  return compose(
    withRouter,
    auth.withAuth
  )(Red)
}

export default withRedirect
