// @flow
import * as React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

type Value = string | number | Array<string | number>;

type Props = {
  name: string;
  error?: string;
  label: string;
  value?: Value;
  options: Array<{
    value: string | number;
    text: string;
  }>;
  onChange?: (name: string, value: Value) => void;
  fullWidth?: boolean;
  multiple?: boolean;
  disabled?: boolean;
}

const FormTextField = ({ name, label, error, value, onChange, multiple, options = [], fullWidth = true, disabled }: Props) => (
  <div>
    <FormControl
      error={Boolean(error)}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        multiple={multiple}
        value={(multiple && !value) ? [] : value || ''}
        onChange={(event: Object) => onChange && onChange(event.target.name, event.target.value)}
        inputProps={{
          name,
          id: name,
        }}
      >
        {options.map((option, index) =>
          <MenuItem key={index} value={option.value}>
            {option.text}
          </MenuItem>
        )}
      </Select>
      {error && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  </div>
)

export default FormTextField
