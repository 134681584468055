// @flow

import * as React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { withStyles } from '@material-ui/core';

type Props = {
  remove: (id: string) => void;
  id: string;
  message: {
    message: string;
    duration: number;
    level: string;
  }
}

const styles = theme => ({
  snackbar: {
    width: 'auto',
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'center'
  }
})

class NotificationItem extends React.Component<Props> {
  render() {
    const { message, classes } = this.props

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
      >
        <SnackbarContent
          message={<span id="message-id">{message.message}</span>}
          className={classes.snackbar}
        />
      </Snackbar>
    )
  }
}

export default withStyles(styles)(NotificationItem)
