// @flow
import * as React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'

type Props = {
  name: string;
  error?: string;
  label: string;
  value?: boolean;
  onChange?: (name: string, value: boolean) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

const FormToggle = ({ name, label, error, value, onChange, fullWidth = true, disabled }: Props) => (
  <FormControl
    error={Boolean(error)}
    fullWidth={fullWidth}
    disabled={disabled}
  >
    <FormControlLabel
      control={
        <Switch
          name={name}
          checked={value}
          onChange={(event: Object, value: boolean) => onChange && onChange(event.target.name, value)}
        />
      }
      label={label}
    />
    {error && (
      <FormHelperText>{error}</FormHelperText>
    )}
  </FormControl>
)

export default FormToggle
