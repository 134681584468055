// @flow

import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {auth, notification, Notification} from 'constructor';
import { RolesService } from './config/service';
import lazyWithRetry from './utils/lazyWithRetry';

import { store } from './store/create';
import routeList from './config/routes';
import roleRoutes from './config/routesByRoles/';

import Menu from './component/layout/menu';
import withRedirect from './hoc/withRedirect';
import NotFound from './config/page/404/';
const Navigation = lazyWithRetry(() => import('./component/layout/navigation'));

// Page list:
import Login from './page/login'
import Recovery from './component/recovery-form'
import NotificationItem from './component/notification'
// Footer
import Footer from './component/layout/Footer/footer'

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { getRoutesByRole } from './config/access';
import { setInitData } from './service/auth';
import { setUserData } from './store/action_creators/config';
import PopupApp from './component/popupApp';
import isEqual from 'lodash/isEqual';


const errorsList = {
    'Account disabled': 'Пользователь заблокирован'
}
const appTypes = [
  'android',
  'mac'
]

library.add(fab);

const ResolvedRoute = compose(
    auth.withResolved,
    withRedirect
)(Route);


class App extends Component {
    state = {
        menuOpen: false,
        role: "",
        filteredRoutes: [],
        isRoutesEmpty: false,
        typeApp: '',
        hiddenAppPopup: localStorage.getItem('hiddenAppPopup')
    }

    toggleMenu = () => {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    closeMenu = () => {
        this.setState({ menuOpen: false });
    }
    handleAvailableRoutes = async () => {
        const routesByRoles = roleRoutes[this.props.user_role];
        const availableRoutes = await getRoutesByRole(routeList, routesByRoles, this.props.user_role, this.props.org_id);
        if (!availableRoutes.length) this.setState({ isRoutesEmpty: true });
        this.setState({ filteredRoutes:  availableRoutes });
    }
    async componentDidMount(){
        window.addEventListener('storage', function (event) {
            if (event.key === 'userId' && !event.oldValue ) {
                window.location.reload()
            }
        });
        await setInitData(this.props, this.props.setUserData);
        if (this.props.user_role) {
            await this.handleAvailableRoutes();
        }

        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('mobile') > -1) {
            const isAndroid = ua.indexOf(appTypes[0]) > -1;
            const isMac = ua.indexOf(appTypes[1]) > -1;
            this.setState({appType: isAndroid && appTypes[0] || isMac && appTypes[1] || ''})
        }
    }
    async componentDidUpdate(prevProps, prevState){
        if (!isEqual(this.props, prevProps)) {
            if (this.props.auth.error === 400 && prevProps.auth.error !== 400) {
                    this.props.notificationActions.set({
                        message: this.props.auth.reasonBlock,
                        duration: 5000,
                    })

            }
            await setInitData(this.props, this.props.setUserData);
            if (this.props.user_role && !this.state.filteredRoutes.length && !this.state.isRoutesEmpty) {
                await this.handleAvailableRoutes();
            }
        }
    }

    render() {
        return (
                <BrowserRouter>
                    {this.state.appType && !this.state.hiddenAppPopup &&
                    <PopupApp
                      appTypes={appTypes}
                      appType={this.state.appType}
                      hiddenAction={(isHidden) => {this.setState({hiddenAppPopup: isHidden })} }
                    ></PopupApp>
                    }
                    <div>
                        <Switch>
                            {/* Auth pages: */}
                                <ResolvedRoute
                                    exact
                                    path='/login'
                                    component={Login}
                                />
                                <ResolvedRoute
                                    exact
                                    path='/recovery'
                                    component={Recovery}
                                />


                            {/* Other pages: */}
                            <ResolvedRoute
                                style={{
                                    flexShrink: '0'
                                }}
                                path='/'
                                render={(props) => (
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'stretch',
                                        minHeight: '100vh'
                                    }}>

                                        <Suspense fallback={<div></div>}>
                                            <Navigation locations={props.location} toggleMenu={this.toggleMenu} />
                                        </Suspense>
                                        <div style={{ position: 'relative', flexGrow: 1 }} onClick={this.closeMenu}>
                                            <Switch>
                                                {
                                                    this.state.filteredRoutes.map((page) => (
                                                        <ResolvedRoute
                                                            exact={page.exact === undefined ? true : page.exact}
                                                            key={page.link}
                                                            path={page.link}
                                                            render={() =><Suspense fallback={<div></div>}><page.component {...page.props} {...props} /></Suspense> }
                                                        />
                                                    ))
                                                }
                                                <Route component={NotFound} />
                                            </Switch>
                                            <Menu
                                                pagesList={this.state.filteredRoutes}
                                                isMenuOpen={this.state.menuOpen}
                                                handleMenu={this.toggleMenu}
                                            />
                                        </div>
                                        <Footer />
                                    </div>
                                )}
                            />
                        </Switch>
                        <Notification Component={NotificationItem} />
                    </div>
                </BrowserRouter>
        )
    }
}
const mapStateToProps = state => ({
    user_role: state.config.user_role,
    org_id: state.config.org_id,
    user_id: state.config.user_id,
    series: state.config.series,
    sections: state.config.sections
});
const mapDispatchToProps = dispatch => ({
    setUserData: payload => dispatch(setUserData(payload)),
});
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    auth.withResolved,
    auth.withAuth,
    notification.withNotification,
)(App);
