// @flow
import React from 'react'
import './styles.css';
import { ViewService } from '../../../config/service'
import logoWhite from '../../../asset/logo_white.png'

const isHmaoSite = process.env.REACT_APP_API_BASE_URL.includes('admhmao')

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#534972',
    },
    altLogo: {
        backgroundImage: `url('${logoWhite}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: 'contain',
        backgroundPosition: "center",
        width: 98,
        height: 30,
        marginLeft: 8,
    },
    htmlContainer: {
        flex: 1,
    }
}

class Footer extends React.Component {
    state = {
        data: {}
    };

    async componentDidMount() {
        await ViewService.getItem({ id: 'footer' })
            .then(response => (
                this.setState({
                    data: response.wysiwyg,
                })
            ))

        let div = document.createElement('div');
        div.innerHTML = this.state.data;
        let html = div.textContent;
        this.setState({ html });
    }
    render() {
        if (isHmaoSite) return (
            <div style={styles.container}>
                <div style={styles.altLogo} />
                <div style={styles.htmlContainer} id="prev" dangerouslySetInnerHTML={{ __html: this.state.html }} />
            </div>
        )

        return (
            <div id="prev" dangerouslySetInnerHTML={{ __html: this.state.html }} />
        )
    }
}

export default Footer;
