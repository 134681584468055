// @flow

import { type LoginResponse } from './duck'
const CACHE_NAME = process.env.REACT_APP_AUTH_CACHE_NAME

export const get = (): ?LoginResponse => {
  if (CACHE_NAME) {
    const cache = localStorage.getItem(CACHE_NAME)
    if (typeof cache === 'string') {
      return JSON.parse(cache)
    }
  } else {
    console.warn('Cache get: "REACT_APP_AUTH_CACHE_NAME" env. не определена')
  }

  return null
}

export const save = (data: LoginResponse) => {
  if (CACHE_NAME) {
    localStorage.setItem(CACHE_NAME, JSON.stringify({
      ...data,
      expires_in: Date.now() / 1000 + data.expires_in,
      refresh_expires_in: Date.now() / 1000 + data.refresh_expires_in,
    }))
  } else {
    console.warn('Cache save: "REACT_APP_AUTH_CACHE_NAME" env. не определена')
  }
}

export const clear = () => {
  if (CACHE_NAME) {
    localStorage.removeItem(CACHE_NAME)
  } else {
    console.warn('Cache clear: "REACT_APP_AUTH_CACHE_NAME" env. не определена')
  }
}
