// @flow

import auth from './module/auth'
import notification from './module/notification'
import widget from './module/widget'

import ServiceApi from './service/api'
import { reducer } from './store/reducer'
import { middleware } from './store/middleware'
import types from './types'

import Notification from './module/notification/container/resolve'

import DataGet from './module/data/container/get'
import DataPost from './module/data/container/post'

import List from './module/widget/component/list'
import Button from './module/widget/component/button'
import Obj from './module/widget/component/object'
import Txt from './module/widget/component/text'
import Form from './module/widget/container/form'
import FormInput from './module/widget/component/form/input'
import FormSelect from './module/widget/component/form/select'
import FormToggle from './module/widget/component/form/toggle'

import * as formHelper from './module/widget/helper/form'

export {
  // List of modules
  auth,
  notification,
  widget,

  // Module components / containers
  Notification,

  // Store
  reducer,
  middleware,

  // Service
  ServiceApi,

  // Helper
  formHelper,

  // Widget components / containers
  List,
  Button,
  Obj,
  Txt,
  Form,
  FormInput,
  FormSelect,
  FormToggle,

  // Data containers
  DataGet,
  DataPost,

  // Types
  types,
}
