// @flow
import * as React from 'react'
import { type Dispatch } from 'redux'
import { connect } from 'react-redux'

import { type Store } from '../../../store/reducer'
import {
  set as actionSet,
  remove as actionRemove,
  type Message,
} from '../duck'

const mapStateToProps = (state: Store) => ({ notification: state.notification })
const mapDispatchToProps = (dispatch: Dispatch) => ({
  set: (message: Message) => dispatch(actionSet(message)),
  remove: (id: string) => dispatch(actionRemove(id))
})

const withNotification = (WrappedComponent: *) => {
  class Notification extends React.Component<*> {
    render() {
      const { notification, set, remove, ...rest } = this.props

      return (
        <WrappedComponent
          notification={notification}
          notificationActions={{
            set,
            remove,
          }}
          {...rest}
        />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Notification)
}

export default withNotification
