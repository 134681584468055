import React from 'react';
import Modal from 'react-modal';

import './modal.css';

const ModalRecovery = (props) => {
  return (
    <Modal
      isOpen={!!props.modalLogic}
      contentLabel='Selected Option'
      onRequestClose={props.handlerCloseModal}
      closeTimeoutMS={300}
      className='modal'
    >
      <p className='modal__option'>{props.modalLogic}</p>
    </Modal>
  )
}

export default ModalRecovery;