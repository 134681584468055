// @flow
import * as React from 'react'
import MButton from '@material-ui/core/Button'

type Props = {
  label: string;
  onClick?: (event: Object) => *;
  isSumbit?: boolean;
  disabled?: boolean;
  style?: Object;
};

const Button = ({ label, onClick, isSumbit, disabled, style }: Props) => (
  <MButton
    variant='outlined'
    onClick={onClick}
    type={isSumbit ? 'submit' : ''}
    disabled={disabled}
    style={style}
  >
    {label}
  </MButton>
)

export default Button
