// @flow
import * as React from 'react'
import _ from 'lodash'

import {
  type Template,
  type ObjScheme,
  type FormScheme,
  type Request,
} from '../../../types'

import Form from '../component/form'
import { validate, validateField, isValidForm } from '../helper/form'
import { fetchAction } from '../../../service/fetch'

type Props = {
  initialData?: {
    [key: string]: any;
  };
  template: Template;
  scheme: FormScheme;
  schemeItemProps?: Object;
  buttonLabel: string;
  ButtonComponent?: React.ComponentType<*>;
  onSubmit: {
    request: Request;
    params?: Object;
  };
}

type State = {
  data: {
    [key: string]: any;
  };
  error: {
    [key: string]: any;
  };
  isLoading: boolean;
}

// const GENERAL_FORM_ERROR = 'Исправте ошибки в форме'

class FormContainer extends React.Component<Props, State> {

  mounted: boolean = false
  state = {
    error: {},
    data: this.props.initialData || {},
    isLoading: false,
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
  }
  
  onChange = (name: string, value: any) => {
    const { scheme } = this.props
    const { data, error } = this.state

    this.setState({
      data: {
        ...data,
        [name]: value,
      },
      error: {
        ...error,
        [name]: validateField(
          _.get(scheme, `${name}.validation`),
          value,
        ),
      },
    })
  }

  validate = () => {
    const { scheme } = this.props
    const { error, data } = this.state

    const updatedErrors = {
      ...error,
      ...validate(scheme, data),
    }

    this.setState({ error: updatedErrors })

    return isValidForm(updatedErrors)
  }

  onSubmit = (event: Object) => {
    const { onSubmit } = this.props
    const { data } = this.state

    event.preventDefault()
    const isValid = this.validate()

    if (isValid) {
      this.setState({ isLoading: true, error: {} })
      fetchAction(onSubmit.request, {
        ...onSubmit.params,
        data,
      })
        .then(() => {
          if (this.mounted) {
            this.setState({ isLoading: false })
          }
        })
        .catch(() => {
          if (this.mounted) {
            this.setState({ isLoading: false })
          }
        })
    } else {
      // handleSnackbar(GENERAL_FORM_ERROR)
    }
  }

  schemeItemProps = (name: string) => {
    const { error } = this.state
    const { scheme, schemeItemProps = {} } = this.props

    return {
      ...schemeItemProps,
      name,
      onChange: this.onChange,
      label: _.get(scheme, `${name}.label`),
      error: _.get(error, name),
    }
  }

  get formSchemeToObject(): ObjScheme {
    const { scheme } = this.props

    return Object
      .keys(scheme)
      .reduce((acc, name) => ({
        ...acc,
        [name]: _.get(scheme, `${name}.component`),
      }), {})
  }

  render() {
    const { template, buttonLabel, ButtonComponent } = this.props
    const { isLoading, data, error } = this.state

    return (
      <Form
        template={template}
        scheme={this.formSchemeToObject}
        buttonLabel={buttonLabel}
        onSubmit={this.onSubmit}
        isLoading={isLoading}
        data={data}
        error={error}
        schemeItemProps={this.schemeItemProps}
        ButtonComponent={ButtonComponent}
      />
    )
  }
}

export default FormContainer
