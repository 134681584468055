import * as React from 'react'

const styles = {
    root: {
        flexGrow: 1,
        position: 'absolute',
        top: -40,
        bottom: 0,
        width: 234,
        transition: '.35s',
        borderRight: '1px solid #e7ecf0',
        backgroundColor: '#fff',
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: 1000,
    },
}

class Drawer extends React.Component<Props> {
    render() {
        const { open, children } = this.props;
        const style = {
            left: open ? 0 : -235,
        };

        return (
            <div style={Object.assign(style, styles.root)}>
                {children}
            </div>
        );
    }
}

export default Drawer
