// @flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import Drawer from './drawer'
import List from '@material-ui/core/List'
import { AccountService } from '../../config/service';
import {items} from '../../page/list/component/specialists/sections';
import {getAccess} from '../../config/access';

type Props = {
    isMenuOpen: boolean;
    pagesList: Array<*>;
    handleMenu: (event: Object) => void;
}

const styles = {
    list: {
        width: 234,
        padding: 0,
    },
    link: {
        padding: 10,
        display: 'flex',
        fontFamily: "HelveticaNeueCyr",
        color: '#534972'
    },
    divider: {
        backgroundColor: '#c9e8e2',
        margin: '0 10px',
        height: 1,
    }
}

const Divider = () => (<div style={styles.divider}></div>);

class Menu extends React.Component<Props> {

    state = {
        data: {},
        isReloaded: false,
    }

    render() {
        const { isMenuOpen, pagesList, handleMenu } = this.props;
        const { data, isReloaded } = this.state;
        return (
            <Drawer open={isMenuOpen} onClose={handleMenu}>
                <div
                    tabIndex={0}
                    role="button"
                    onClick={handleMenu}
                    onKeyDown={handleMenu}
                >
                    <List style={styles.list}>
                        {pagesList.filter(page => page.showInMenu).map((page, i, arr) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className="menu-item">
                                        <Link to={page.link} onClick={handleMenu} style={styles.link}>
                                            {page.title}
                                        </Link>
                                    </div>
                                    {
                                        i !== (arr.length - 1) && <Divider />
                                    }
                                </React.Fragment>
                            )
                        })}
                    </List>
                </div>
            </Drawer>
        )
    }
}


export default Menu;
