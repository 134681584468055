// @flow
import * as React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'

import Obj from './object'
import Button from './button'

import {
  type Template,
  type ObjScheme,
} from '../../../types'

type Props = {
  data: {
    [key: string]: any;
  };
  isLoading: boolean;
  template: Template;
  scheme: ObjScheme;
  buttonLabel: string;
  onSubmit: (event: Object) => void;
  schemeItemProps?: (name: string) => Object;
  ButtonComponent?: React.ComponentType<*>;
};

class Form extends React.Component<Props> {
  render() {
    const { data, template, scheme, buttonLabel, onSubmit, isLoading, schemeItemProps, ButtonComponent } = this.props
    const Btn = ButtonComponent || Button

    return (
      <form style={{ paddingBottom: 30 }}>
        <div style={{ paddingBottom: 15 }}>
          <Obj
            data={data}
            template={template}
            scheme={scheme}
            schemeItemProps={schemeItemProps}
          />
        </div>
        <Grid fluid>
          <Row>
            <Col lg={12}>
              <Btn
                isSumbit
                label={buttonLabel}
                onClick={isLoading ? () => {} : onSubmit}
                disabled={isLoading}
              />
            </Col>
          </Row>
        </Grid>
      </form>
    )
  }
}

export default Form
