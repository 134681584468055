export default {
    "/check": {
        show_in_menu: false,
        render_in_route: true
    },
    "/accounts": {
        show_in_menu: true,
        render_in_route: true
    },
    "/accounts/create": {
        show_in_menu: false,
        render_in_route: true
    },
    "/accounts/:id/view/:page/:tab": {
        show_in_menu: false,
        render_in_route: true
    },
    "/accounts/:id/view/:page/:tab/:tab2": {
        show_in_menu: false,
        render_in_route: true
    },
    "/accounts/:id/create/:page/:tab": {
        show_in_menu: false,
        render_in_route: true
    },
    "/accounts/:id/update/:page/:tab/:id": {
        show_in_menu: false,
        render_in_route: true
    },
    "/account/:id/update": {
        show_in_menu: false,
        render_in_route: true
    },
    "/admin": {
        show_in_menu: true,
        render_in_route: true
    },
    "/directory": {
        show_in_menu: true,
        render_in_route: true
    },
    "/decor/1/view/account/footer": {
        show_in_menu: true,
        render_in_route: true
    },
    "/decor/1/view/account/banners": {
        show_in_menu: false,
        render_in_route: true
    },
    "/diagnostics": {
        show_in_menu: true,
        render_in_route: true
    },
    "/monitoring/settings/programs": {
        show_in_menu: true,
        render_in_route: true
    },
    "/monitoring/settings": {
        show_in_menu: false,
        render_in_route: true
    },
    "/monitoring/settings/:page": {
        show_in_menu: false,
        render_in_route: true
    },
    "/monitoring/settings/:page/:page": {
        show_in_menu: false,
        render_in_route: true
    },
    "/monitoring/settings/:page/:page/:id": {
        show_in_menu: false,
        render_in_route: true
    },
    "/profiles/1": {
        show_in_menu: true,
        render_in_route: true
    },
    "/profiles/2": {
        show_in_menu: true,
        render_in_route: true
    },
    "/profiles/3": {
        show_in_menu: true,
        render_in_route: true
    },
    "/profiles/4": {
        show_in_menu: true,
        render_in_route: true
    },
    "/guides/1": {
        show_in_menu: true,
        render_in_route: true
    },
    "/guides/2": {
        show_in_menu: true,
        render_in_route: true
    },
    "/guides/3": {
        show_in_menu: true,
        render_in_route: true
    },
    "/guides/4": {
        show_in_menu: true,
        render_in_route: true
    },
    "/accounting": {
        show_in_menu: true,
        render_in_route: true
    },
    "/accounting/details": {
        show_in_menu: false,
        render_in_route: true
    }
}
