import staff from './staff';
import super_admin from './super_admin';
import trustee from './trustee';
import user from './user';

export default {
    staff,
    super_admin,
    trustee,
    user
}