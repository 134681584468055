import React from 'react';
import lazyWithRetry from '../utils/lazyWithRetry';
import { getProfileName, getProfileTitle } from '../utils/profileUtils'; 

const CheckUser = lazyWithRetry(() => import('./page/check/index'));
const NotFound = lazyWithRetry(() => import('./page/404/index'));
const ContractCreate = lazyWithRetry(() => import('./page/contract/create'));
const ContractTable = lazyWithRetry(() => import('./page/contract/search'));
const ContractView = lazyWithRetry(() => import('./page/contract/view'));
const MessagesView = lazyWithRetry(() => import('./page/messages/index'));
const MonitoringPage = lazyWithRetry(() => import('./page/monitoring/view'));
const AdsView = lazyWithRetry(() => import('./page/messages/viewAds'));
const ContractUpdate = lazyWithRetry(() => import('./page/contract/update'));
const ChildrenTable = lazyWithRetry(() => import('./page/children/search'));
const GroupCreate = lazyWithRetry(() => import('./page/group/create'));
const Group = lazyWithRetry(() => import('./page/group/view'));
const GroupTable = lazyWithRetry(() => import('./page/group/search'));
const ChildDashboard = lazyWithRetry(() => import('../page/childDashboard'));
const GroupDashboard = lazyWithRetry(() => import('../page/groupDashboard'));
const ForMenuBimbo = lazyWithRetry(() => import('../component/forMenuBimbo'));
const Child = lazyWithRetry(() => import('./page/children/child'));
const AccountTable = lazyWithRetry(() => import('./page/account/table'));
const AccountCreate = lazyWithRetry(() => import('./page/account/create'));
const AccountUpdate = lazyWithRetry(() => import('./page/account/update'));
const AccountView = lazyWithRetry(() => import('./page/account/view'));
const AccountUpdateUser = lazyWithRetry(() => import('./page/account/updateUser'));
const AccountCreateUser = lazyWithRetry(() => import('./page/account/createUser'));
const DecorView = lazyWithRetry(() => import('./page/decor/view'));
const Directory = lazyWithRetry(() => import('./page/directory/view'));
const Diagnostics = lazyWithRetry(() => import('./page/diagnostics/view'));
const AdminTable = lazyWithRetry(() => import('./page/admin/table'));
const Food = lazyWithRetry(() => import('./page/food/view'));
const FoodCreate = lazyWithRetry(() => import('./page/food/create'));
const FoodEditor = lazyWithRetry(() => import('./page/food/editor'));
const FoodOne = lazyWithRetry(() => import('./page/food/index'));
const ContactsView = lazyWithRetry(() => import('./page/contacts/index'));
const PlanningView = lazyWithRetry(() => import('./page/planning/index'));
const Trustees = lazyWithRetry(() => import('./page/trustees/list'));
const TrusteesView = lazyWithRetry(() => import('./page/trustees/list'));
const Specialists = lazyWithRetry(() => import('../page/list/component/specialists/list'));
const SpecialistCard = lazyWithRetry(() => import('../page/list/component/specialists/card'));
const LettersView = lazyWithRetry(() => import('../modules/letters/index'));
const LettersFormSelector = lazyWithRetry(() => import('../modules/letters/forms/formSelector'));
const Document = lazyWithRetry(() => import('./page/diagnostics/results/document'));
const Accounting = lazyWithRetry(() => import('./page/accounting'))
const Cabinet = lazyWithRetry(() => import('./page/cabinet'))


const Profile1 = lazyWithRetry(() => import('./page/profiles/view'))
const Profile2 = lazyWithRetry(() => import('./page/profiles/view'))
const Profile3 = lazyWithRetry(() => import('./page/profiles/view'))
const Profile4 = lazyWithRetry(() => import('./page/profiles/view'))

const Guide1 = lazyWithRetry(() => import('./page/profilesGuide/view'))
const Guide2 = lazyWithRetry(() => import('./page/profilesGuide/view'))
const Guide3 = lazyWithRetry(() => import('./page/profilesGuide/view'))
const Guide4 = lazyWithRetry(() => import('./page/profilesGuide/view'))

const routeList = [
	{
		exact: false,
		link: '/check',
		component: CheckUser,
		title: 'Проверка прав пользователя',
		showInMenu: false,
	},
	{
		link: '/pages/404',
		component: NotFound,
		title: 'Данной страницы не существует',
		showInMenu: false,
	},
	{
		exact: false,
		link: '/contracts/create',
		component: ContractCreate,
		title: 'Добавить договор',
		name: 'contracts',
		showInMenu: false,
	},
	{
		link: '/groups/create',
		component: GroupCreate,
		title: 'Группы',
		name: 'groups',
		showInMenu: false,
	},
	{
		link: '/',
		component: ContractTable,
		title: 'Договоры',
		showInMenu: false,
	},
	{
		link: '/contracts',
		component: ContractTable,
		title: 'Договоры',
		showInMenu: true,
		name: 'contracts',
		id: 1
	},
	{
		link: '/contracts/:id',
		component: ContractView,
		title: 'Договоры',
		name: 'contracts',
		showInMenu: false,
	},
	{
		link: '/contracts/:id/update',
		component: ContractUpdate,
		title: 'Договоры',
		name: 'contracts',
		showInMenu: false,
	},
	{
		link: '/children',
		component: ChildrenTable,
		title: 'Дети',
		showInMenu: true,
		name: 'children',
		id: 2
	},
	{
		link: '/groups',
		component: GroupTable,
		title: 'Группы',
		showInMenu: true,
		name: 'groups',
		id: 3
	},
	{
		link: '/groups/:id/card/:page/:tab',
		component: Group,
		title: 'Сотрудники',
		name: 'groups',
		showInMenu: false,
	},
	{
		link: '/children/card/:id',
		component: Child,
		title: 'Сотрудники',
		showInMenu: false,
		name: 'children'
	},
	{
		link: '/children/results/:document',
		component: Document,
		title: 'Результаты диагностического тестирования',
		showInMenu: false,
		name: 'children'
	},
	{
		link: '/children/:id/card/:page/:tab',
		component: Child,
		title: 'Сотрудники',
		showInMenu: false,
		name: 'children'
	},
	{
		link: '/specialists',
		component: Specialists,
        title: 'Сотрудники',
		showInMenu: true,
		name: 'spec',
		id: 4
    },
	{
		link: '/specialists/:id',
		component: SpecialistCard,
		title: 'Сотрудники',
		name: 'spec',
		showInMenu: false,
	},
	{
		link: '/trustees',
		component: Trustees,
		title: 'Держатели',
		showInMenu: true,
		name: 'trustees',
		id: 10
	},{
		link: '/trustees/:id',
		component: TrusteesView,
		title: 'Держатели',
		showInMenu: false,
		name: 'trustees',
	},
	{
		link: '/group/:id/dashboard',
		component: GroupDashboard,
		title: 'Dashboard',
		name: 'groups',
		showInMenu: false,
	},
	{
		link: '/dumb',
		component: ForMenuBimbo,
		title: 'Задачи',
		showInMenu: true,
		name: 'worksplan',
		id: 7
	},
	{
		link: '/contacts',
		component: ContactsView,
		title: 'Контакты',
		showInMenu: true,
		name: 'contacts',
		id: 8
	},
	{
		link: '/messages',
		component: MessagesView,
		title: 'Объявления и сообщения',
		showInMenu: true,
		name: 'messages',
		id: 9
	},{
		link: '/messages/create',
		component: MessagesView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'messages',
	},{
		link: '/messages/edit/:id',
		component: MessagesView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'messages',
	},	{
		link: '/information',
		component: MessagesView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'messages',
		id: 9
	},{
		link: '/information/create',
		component: MessagesView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'messages',
	},{
		link: '/information/edit/:id',
		component: MessagesView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'messages',
	},
	{
		link: '/advertisement',
		component: AdsView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'advertisement'
	},
	{
		link: '/advertisement/:id',
		component: AdsView,
		title: 'Объявления и сообщения',
		showInMenu: false,
		name: 'advertisement'
	},
	{
		link: '/dumb',
		component: ForMenuBimbo,
		title: 'Взаимодействие',
		showInMenu: true,
		name: 'collaboration',
		id: 5
	},
	{
		link: '/dumb',
		component: ForMenuBimbo,
		title: 'Обратная связь',
		showInMenu: true,
		name: 'feedback',
		id: 11
	},
	{
		link: '/food/create',
		component: FoodCreate,
		title: 'Питание',
		showInMenu: false,
		// name: 'food_create'
		name: 'food',
	},
	{
		link: '/food/editor',
		component: FoodEditor,
		title: 'Питание',
		showInMenu: false,
		// name: 'food_create'
		name: 'food',
	},
	{
		link: '/food/create/:id',
		component: FoodCreate,
		title: 'Питание',
		showInMenu: false,
		// name: 'food_create',
		name: 'food',
	},
	{
		link: '/food/one',
		component: FoodOne,
		title: 'Питание',
		showInMenu: true,
		// name: 'food_one',
		name: 'food',
		id: 12
	},
	{
		link: '/food/one/:id',
		component: FoodOne,
		title: 'Питание',
		showInMenu: false,
		// name: 'food_one',
		name: 'food',
	},
	{
		link: '/dumb',
		component: ForMenuBimbo,
		title: 'База знаний',
		showInMenu: true,
		name: 'baseKnowledge',
		id: 13
	},
	{
		link: '/dumb',
		component: ForMenuBimbo,
		title: 'Конструктор занятий',
		showInMenu: true,
		name: 'lessonDesigner',
		id: 14
	},
	{
		link: '/dumb',
		component: ForMenuBimbo,
		title: 'Медиатека',
		showInMenu: true,
		name: 'mediaLibrary',
		id: 15
	},
	// {
	//     link: '/diagnostics/conclusion/step/:step',
	//     component: Conclusion,
	//     // title: 'Диагностическое тестирование',
	//     showInMenu: false,
	//     exact: false,
	// },
	// {
	//     link: '/diagnostics/survey',
	//     component: Survey,
	//     title: 'Диагностическое тестирование',
	//     showInMenu: true,
	//     exact: true,
	// },

	{
		link: '/diagnostics',
		component: Diagnostics,
		title: 'Диагностика',
		showInMenu: true,
		exact: false,
		name: 'diagnostics',
		id: 16
	},
	{
		link: '/diagnostics/spider/:groupId/:childId/:fio',
		title: 'test',
		// "component" declared insdie "Diagnostics" up above
	},
	{
		link: '/diagnostics/tests',
		title: 'Тесты',
		// "component" declared insdie "Diagnostics" up above
	},
	{
		link: '/diagnostics/tests/create',
		title: 'Тесты',
		// "component" declared insdie "Diagnostics" up above
	},
	{
		link: '/diagnostics/results',
		title: 'Результаты',
		// "component" declared insdie "Diagnostics" up above
	},
	{
		link: '/planning',
		component: PlanningView,
		title: 'Расписание',
		showInMenu: true,
		name: 'calendar',
		id: 18
	},{
		link: '/planning/:id',
		component: PlanningView,
		title: 'Расписание',
		name: 'calendar',
	},
	{
		link: '/planning/create',
		component: PlanningView,
		title: 'Расписание',
		name: 'calendar',
	},
	{
		link: '/planning/:id/edit',
		component: PlanningView,
		title: 'Расписание',
		name: 'calendar',
	},
	{
		link: '/accounts',
		component: AccountTable,
		title: 'Аккаунты учреждений',
		showInMenu: true,
	},
	{
		link: '/accounts/create',
		component: AccountCreate,
		title: 'Аккаунты учреждений',
		showInMenu: false,
	},
	{
		link: '/account/:id/update',
		component: AccountUpdate,
		title: 'Аккаунты учреждений',
		showInMenu: false,
	},
	{
		link: '/accounts/:id/view/:page/:tab',
		component: AccountView,
		title: 'Аккаунты учреждений',
		showInMenu: false,
	},
	{
		link: '/accounts/:id/view/:page/:tab/:tab2',
		component: AccountView,
		title: 'Аккаунты учреждений',
		showInMenu: false,
	},
	{
		link: '/accounts/:id/update/:page/:tab/:id',
		component: AccountUpdateUser,
		title: 'Аккаунты учреждений',
		showInMenu: false,
	},
	{
		link: '/accounts/:id/create/:page/:tab',
		component: AccountCreateUser,
		title: 'Аккаунты учреждений',
		showInMenu: false,
	},
	// Бухгалтерия
	{
		link: '/accounting',
		component: Accounting,
		title: 'Бухгалтерия',
		name: 'accounting',
		showInMenu: true,
	},
	{
		link: '/accounting/details',
		component: Accounting,
		title: 'Профиль'
	},
	{
		link: '/admin',
		component: AdminTable,
		title: 'Администраторы SSPRO',
		showInMenu: true,
	},
	{
		link: '/decor/1/view/account/footer',
		component: DecorView,
		title: 'Оформление',
		showInMenu: true,
	},
	{
		link: '/decor/1/view/account/banners',
		component: DecorView,
		title: 'Оформление',
		showInMenu: false,
	},
	{
		link: '/directory',
		component: Directory,
		title: 'Справочники',
		showInMenu: true,
	},
	{
		link: '/children/:id/dashboard',
		component: ChildDashboard,
		title: 'Dashboard',
		name: 'children',
		showInMenu: false,
	},
	{
		link: '/letters',
		component: LettersView,
		title: 'Письма',
		showInMenu: true,
		exact: true,
		name: 'letters',
		id: 20
	},
	{
		link: '/letters/create',
		component: LettersFormSelector,
		props: {
			formType: 'CREATE',
		},
		title: 'Создание письма',
		name: 'letters',
		showInMenu: false
	},
	{
		link: '/letters/edit/:letterID',
		component: LettersFormSelector,
		props: {
			formType: 'EDIT',
		},
		title: 'Редактирование письма',
		name: 'letters',
		showInMenu: false
	},
	// monitoring \/ \/ \/
	{
		link: '/monitoring/settings',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
	{
		link: '/monitoring/settings/programs',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		showInMenu: false
	},
	{
		link: '/monitoring/settings/:page',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
	{
		link: '/monitoring/settings/:page/:page',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
	{
		link: '/monitoring/settings/:page/:page/:id',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
	{
		link: '/monitoring/monitorings',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: true
	},
	{
		link: '/monitoring/monitorings/:page',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
	{
		link: '/monitoring/monitorings/:page/:id',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
	{
		link: '/monitoring/control-panel',
		component: MonitoringPage,
		props: {
			formType: 'EDIT',
		},
		title: 'Мониторинг',
		name: 'monitoring',
		showInMenu: false
	},
		// Личный кабинет
	{
		link: '/cabinet',
		component: Cabinet,
		title: 'Личный кабинет',
		showInMenu: true,
		name: 'cabinet',
		id: 24
	},
	{
		link: '/cabinet/documents',
		component: Cabinet,
		title: 'Личный кабинет',
		name: 'cabinet'
	},
		// Профили и справочники =======================================================
	{
		link: '/profiles/1',
		component: Profile1,
		title: `${getProfileTitle('1')}`,
		showInMenu: true,
		exact: false,
		name: `${getProfileName('1')}`,
	},
	{
		link: '/guides/1',
		component: Guide1,
		title: `Справочник ${getProfileTitle('1')}`,
		showInMenu: true,
	},
	{
		link: '/profiles/2',
		component: Profile2,
		title: `${getProfileTitle('2')}`,
		name: `${getProfileName('2')}`,
		exact: false,
		showInMenu: true,
	},
	{
		link: '/guides/2',
		component: Guide2,
		title: `Справочник ${getProfileTitle('2')}`,
		showInMenu: true,
	},
	{
		link: '/profiles/3',
		component: Profile3,
		title: `${getProfileTitle('3')}`,
		name: `${getProfileName('3')}`,
		exact: false,
		showInMenu: true,
	},
	{
		link: '/guides/3',
		component: Guide3,
		title: `Справочник ${getProfileTitle('3')}`,
		showInMenu: true,
	},
	{
		link: '/profiles/4',
		component: Profile4,
		title: `${getProfileTitle('4')}`,
		name: `${getProfileName('4')}`,
		exact: false,
		showInMenu: true,
	},
	{
		link: '/guides/4',
		component: Guide4,
		title: `Справочник ${getProfileTitle('4')}`,
		showInMenu: true,
	},
];

export default routeList;
