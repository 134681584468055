// @flow
import { combineReducers } from 'redux'
import auth, { type State as AuthState } from '../module/auth/duck'
import notification, { type State as NotificationState } from '../module/notification/duck'

export type Store = {
  auth: AuthState;
  notification: NotificationState;
};

export const reducer = combineReducers({
  auth,
  notification,
})
