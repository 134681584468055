// @flow
import * as React from 'react'

import LoginForm from '../container/login-form'

const LoginPage = () => (
  <LoginForm />
)

export default LoginPage
