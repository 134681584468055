// @flow
import * as React from 'react'

import withNotification from '../hoc/withNotification'
import NotificationItem from '../component/item'

import { type State, type Message } from '../duck'

type Props = {
  notification?: State;
  notificationActions?: {
    set: (message: Message) => void;
    remove: (id: string) => void;
  };
  Component: React.ComponentType<*>;
  manual?: boolean;
}

class NotificationResolveContainer extends React.PureComponent<Props> {
  render() {
    const { notification, notificationActions, Component, manual } = this.props

    if (
      !notification ||
      !notificationActions ||
      !Object.keys(notification) === 0
    ) {
      return null
    }

    return Object
      .keys(notification)
      .map(id => (
        <NotificationItem
          key={id}
          id={id}
          message={notification[id]}
          remove={notificationActions.remove}
          Component={Component}
          manual={manual}
        />
      ))
  }
}

export default withNotification(NotificationResolveContainer)
