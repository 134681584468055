// @flow

import { createSelector } from 'reselect'
import _ from 'lodash'

import {
  type Template,
  type ObjScheme,
  type MappedSchemeToTemplate,
} from '../../../types'

export const mappedSchemeToTemplate: (state: {
  template: Template;
  scheme: ObjScheme;
}) => MappedSchemeToTemplate = createSelector(
  state => state,
  ({ template, scheme }) => {
    let index = 0
    let structure = []
    const SchemeKeys = Object.keys(scheme)
    
    if (_.flattenDeep(template).length > SchemeKeys.length) {
      // eslint-disable-next-line
      console.warn('Количество колонок в шаблоне превышает количество элементов!')
    } else if (_.flattenDeep(template).length < SchemeKeys.length) {
      // eslint-disable-next-line
      console.warn('Количество элементов в схеме превышает количество допустимых элементов в шаблоне!')
    }

    template.forEach(row => {
      let currentRow = []

      row.forEach(col => {
        const name = SchemeKeys[index]
        const Component = _.get(scheme, name)

        if (!Component) {
          return structure
        }

        currentRow.push({
          ...col,
          name,
          Component,
        })

        index = index + 1
      })

      if (currentRow.length !== 0) {
        structure.push(currentRow)
      }
    })

    return structure
  }
)
