// @flow
import React, { Component } from 'react'

class Centred extends Component<*> {
  render() {
    return (
      <div style={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        ...this.props.style
      }}>
        {this.props.children}
      </div>
    )
  }
}

export default Centred
