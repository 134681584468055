import { SET_USER_DATA, CLEAR_USER_DATA } from '../actions';
import { config } from '../initState';

export default (state = config, action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                org_id: action.payload.org_id,
                user_role: action.payload.user_role,
                user_id: action.payload.user_id,
                sections: action.payload.sections ? action.payload.sections : [],
                series: action.payload.series ? action.payload.series : []
            };
        case CLEAR_USER_DATA:
            return config;
        default: 
            return state;
    }
}