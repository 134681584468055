// @flow
import * as React from 'react'

import { type Message } from '../duck'

type Props = {
  id: string;
  message: Message;
  remove: (id: string) => void;
  Component: React.ComponentType<*>;
  manual?: boolean;
}

class NotificationItem extends React.Component<Props> {
  componentDidMount() {
    const { manual } = this.props

    if (!manual) {
      this.remove()
    }
  }

  remove = () => {
    const { id, remove, message } = this.props

    setTimeout(() => {
      remove(id)
    }, message.duration)
  }

  render() {
    const { Component, id, remove, message } = this.props

    return (
      <Component
        id={id}
        remove={remove}
        message={message}
      />
    )
  }
}

export default NotificationItem
