// @flow
import * as React from 'react'
import { type Dispatch } from 'redux'
import { connect } from 'react-redux'

import { getCachedData } from '../duck'
import { type Store } from '../../../store/reducer'

type Props = {
  handleSnackbar: (message: string) => void;
  auth: $PropertyType<Store, 'auth'>;
  dispatch: Dispatch;
}

const mapStateToProps = (state: Store) => ({ auth: state.auth })
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch })

const withResolved = (WrappedComponent: *) => {
  class Resolve extends React.Component<Props> {
    componentDidMount() {
      const { dispatch } = this.props

      dispatch(getCachedData())
    }

    render() {
      const { auth, ...rest } = this.props

      // If user is not defined - do not show anything
      if (auth.data.user === undefined) {
        return null
      }

      // User has been fetched - now you decide what to do.
      return (
        <WrappedComponent
          {...rest}
        />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Resolve)
}

export default withResolved
