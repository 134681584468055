import React, {useState, useEffect} from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import LogoMobile from '../asset/icons/logo_mobile.png';
import './popupAppStyle.css'
import Button from '../component/layout/button';

const android = {
  package: 'pro.smartschool',
  scheme: 'pro.smartschool',
  fallbackUrl: 'https://play.google.com/store/apps/details?id=pro.smartschool&hl=en_US&gl=US',
}

const mac = {
  sheme: 'smartschool.pro'
}

const PopupApp = ({appType, appTypes, hiddenAction}) => {

  const [urlApp, setUrlApp] = useState('')

  useEffect(() => {

    if (appType === appTypes[0]) { //android
      setUrlApp(`intent://${android.scheme}/#Intent;scheme=${android.scheme};package=${android.package};end`)
    }

    if (appType === appTypes[1]) { //mac
      setUrlApp(`${mac.sheme}://`)
    }
  }, [])


  return (
    <Grid className='popup-app-container'>
      <a href={urlApp} style={{'width': '100%'}} onClick={() => {
        if (localStorage.getItem('account-auth-token')) {
          hiddenAction(true)
          localStorage.setItem('hiddenAppPopup', 'true')
        }
      }}>
        <Row className='popup-app-wrapper' middle="xs">
          <Col xs={2}><div className='mobile-icon-wrapper'>
            <img src={LogoMobile} width={50} height={50}/>
          </div></Col>
          <Col xs={6}>
            <div style={{'fontSize': '14px'}}>Открыть в приложении</div>
          </Col>
          <Col xs={4}>
            <Button label='Открыть' onClick={() => {}} primary></Button>
          </Col>
        </Row>
      </a>
    </Grid>
  )
}
export default (PopupApp);
