// @flow
import * as React from 'react'
import { type Dispatch } from 'redux'
import { connect } from 'react-redux'

import { type Store } from '../../../store/reducer'
import {
  login as actionLogin,
  logout as actionLogout,
  type State,
  type LoginPayload,
} from '../duck'

type Props = {
  auth: State;
  login: (payload: LoginPayload) => Promise<void>;
  logout: () => void;
}

const mapStateToProps = (state: Store) => ({ auth: state.auth })
const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (payload: LoginPayload) => dispatch(actionLogin(payload)),
  logout: () => dispatch(actionLogout())
})

const withAuth = (WrappedComponent: *) => {
  class Auth extends React.Component<Props & *> {
    render() {
      const { auth, logout, login, ...rest } = this.props

      return (
        <WrappedComponent
          auth={auth}
          authActions={{
            login,
            logout,
          }}
          {...rest}
        />
      )
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Auth)
}

export default withAuth
