export const addSlash = (url: string) => url[url.length-1] === '/' ? url : url+'/';
export const errorHandler = (message: string, error) => {
    console.error(error);
    return { message };
};
export const successHandler = (message: string, data) => ({ data, message });

export const handleQueryChange = (string, queryParam) => {
    if (!string) return queryParam;
    const [ targetParam, targetValue ] = queryParam.split('=');
    const params = string.slice(1).split('&');
    const isHasTargetParam = params.find(param => param.split('=')[0] == targetParam);
    if (isHasTargetParam) return params.map(param => param.split('=')[0] == targetParam ? `${targetParam}=${targetValue}` : param).join('&');
    else return `${string}&${queryParam}`;
}
export const excludeQueryParam = (string, param) => {
    return string.split('&').filter(item => item.split('=')[0] !== param).join('&');
}
// Добавлен параметр, для того, чтобы получился другой запрос GET и данные не брались из кэша
// typeFile: 'xlsx'
export const downLoadBlobFile = (service, params: object, fileName: string, id) => {
    service({ id, params: {...params, typeFile: 'xlsx'} }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `${fileName}`,
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url)
    });
}

export const debounce = (callback, delay) => {
    let debounceTimer;
    return (...args) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => callback(...args), delay);
    };
  };

export const getQueryParamsValue = (search, queryParam) => {
    const urlParts = search.split('?')
    const params = new URLSearchParams(urlParts[1])
    return params.get(queryParam) ? decodeURI(params.get(queryParam)) : null
}
export const deleteQueryParamsValue = (search, queryParam) => {
    const urlParts = search.split('?')
    const params = new URLSearchParams(urlParts[1])
    params.delete(queryParam)
    return decodeURI(params).length !==0 ? `?${decodeURI(params)}` : decodeURI(params)
}

export const getYesNoLabel = (value) => {
	switch (value) {
		// case '-1': return 'Нет данных';
		case '1': return 'Да';
		case '0': return 'Нет';
		default:
			return '';
	}
}
export const getPlusMinusLabel = (value) => {
	switch (value) {
		// case '-1': return 'Нет данных';
		case '1': return '+';
		case '0': return '-';
		default:
			return '';
	}
}