// @flow
import axios, { type AxiosInstance } from 'axios'
import { fetcher as createFetcher, fetcherGet as fetcherGetMethods } from './fetch'

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT || 10000,
  headers: {
    'Content-Type': 'application/json',
  }
})

class ApiService {
  constructor(entityName: string, instance?: AxiosInstance = defaultInstance) {
    this.apiUrl = `/${entityName}`
    this.entityName = entityName
    this.fetcher = createFetcher(instance)
    this.fetcherGet = fetcherGetMethods(instance)
  }

  apiUrl: string;
  entityName: string;
  instance: AxiosInstance;
  fetcher: (dataSource: string, params?: *) => Promise<any>;
  fetcherGet: (dataSource: string, params?: *) => Promise<any>;

  getList = (data: { params?: Object } = {}) => this.fetcherGet(this.apiUrl, { method: 'GET', ...data })
  getItem = ({ id, params = {} }: { id: string, params?: Object }) => this.fetcherGet(`${this.apiUrl}/${id}`, { method: 'GET', params })
  getRelatedList = (entityName: string) => ({ id, params = {} }: { id: string, params?: Object }) => this.fetcherGet(`${this.apiUrl}/${id}/${entityName}`, { method: 'GET', params })
  getRelatedItem = (entityName: string) => ({ id, params = {} }: { id: string, params?: Object }) => this.fetcherGet(`${this.apiUrl}/${id}/${entityName}`, { method: 'GET', params })
  postItem = ({ data }: { data: Object }) => this.fetcher(this.apiUrl, { method: 'POST', data })
  patchItem = ({ id, data }: { id: string, data: Object }) => this.fetcher(`${this.apiUrl}/${id}`, { method: 'PATCH', data })
  deleteItem = ({ id }: { id: string }) => this.fetcher(`${this.apiUrl}/${id}`, { method: 'DELETE' })
  putItem = ({ id, data }: { id: string, data: Object }) => this.fetcher(`${this.apiUrl}/${id}`, { method: 'PUT', data })

list = (actionName: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE' = 'GET') =>
  ({ data = {}, params = {}, }: { data?: Object, params?: Object }) => {
    if (method === 'GET') {
      return this.fetcherGet(`${ this.apiUrl }/${ actionName }`, { method, data, params })
    } else {
      return this.fetcher(`${ this.apiUrl }/${ actionName }`, { method, data, params })
    }
  }

  item = (actionName: string, method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' = 'POST') =>
    ({ id, data = {}, params = {}, }: { id: string, data?: Object, params?: Object }) => {
      if (method === 'GET') {
        return this.fetcherGet(`${this.apiUrl}/${actionName}`, { method, data, params })
      } else {
        return this.fetcher(`${ this.apiUrl }/${ id }/${ actionName }`, { method, data, params })
      }
    }
}

export default ApiService
