// @flow
import * as React from 'react'

import Loader from '../../layout/component/loader'
import { fetchAction } from '../../../service/fetch'

import {
  type Request,
} from '../../../types'

type Props = {
  perform: Request;
  children: (args: {
    data: any;
    send: () => Promise<void>;
    isLoading: boolean;
  }) => React.Node;
  params?: Object;
  hideLoader?: boolean;
}

type State = {
  isLoading: boolean;
  data?: any;
  error?: string;
}

class DataPost extends React.Component<Props, State> {
  state = {
    isLoading: false,
    data: undefined,
    error: undefined,
  }

  send = (dynamicParams: any = {}) => {
    const { perform, params } = this.props

    this.setState({ isLoading: true })

    return fetchAction(perform, {
      ...params,
      ...dynamicParams,
    })
      .then((response) => {
        this.setState({
          isLoading: false,
          data: response,
        })
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          error,
        })
      })
  }

  render() {
    const { children, hideLoader } = this.props
    const { data, isLoading } = this.state

    if (isLoading && !hideLoader) {
      return <Loader active />
    }

    return children({
      send: this.send,
      isLoading,
      data,
    })
  }
}

export default DataPost
