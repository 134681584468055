import leftGradient from '../asset/left-gradient.png';
import butterfly from '../asset/butterfly.png';

const bodyWidth = '1152px';
const padding = '0 40px';

export default {
    authContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh',
        minWidth: bodyWidth
    },
    header: {
        display: 'flex',
        justifyContent: 'center'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',

        height: '192px',

        minWidth: bodyWidth,
        padding: padding,
        boxSizing: 'border-box'
    },
    logo: {
        paddingBottom: '10px',
    },
    caption: {
        fontSize: '40px',
        fontFamily: "HelveticaNeueCyr",
        color: '#3f444a',
        lineHeight: 1.2,
        whiteSpace: 'pre',
    },
    body: {
        display: 'flex',
    },
    leftSide: {
        flexGrow: 1,
        backgroundImage: `url('${leftGradient}')`,
    },
    rightSide: {
        flexGrow: 1,
    },
    form: {
        width: bodyWidth,
        height: '467px',

        display: 'flex',
        flexDirection: 'column',

        backgroundImage: `url('${butterfly}')`,
    },
    offset: {
        height: '86px',
    },
    welcome: {
        height: '134px',
        paddingLeft: '174px',
        display: 'flex',
        alignItems: 'center',

        fontSize: '26px',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(255, 255, 255)',
        textTransform: 'uppercase',
        lineHeight: 1.2,
    },
    label: {
        width: '174px',
        paddingRight: '14px',
        boxSizing: 'border-box',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',

        fontSize: '18px',
        fontWeight: 'lighter',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(255, 255, 255)',
    },
    input: {
        boxSizing: 'border-box',
        height: '100%',
        width: '300px',
        padding: '0 5px',
        border: '1px solid rgb(200, 200, 200)',
    },
    submit: {
        height: '100%',
        marginLeft: '27px',
        padding: '0 15px',
        borderRadius: '2px',
        textTransform: 'uppercase',
        border: 'none',
        backgroundColor: '#7777af',
        cursor: 'pointer',
        boxSizing: 'border-box',

        fontSize: '18px',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(255, 255, 255)',
        lineHeight: 1.2,
    },
    loginPassword: {
        height: '39px',
        display: 'flex',
        marginBottom: '10px',
    },
    description: {
        display: 'flex',
        width: 300,
        marginBottom: '10px',

        paddingLeft: '174px',
        fontSize: '14px',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(255, 255, 255)',
        lineHeight: 1.3,
    },
    restore: {
        marginLeft: '174px',
        fontSize: '14px',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(255, 255, 255)',
        textDecoration: 'underline',
        lineHeight: 1.2,
        width: '150px'
    },
    pictograms: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'hidden',
        border: '1px solid transparent',
        minHeight: 152,
    },
    pictogramsContainer: {
        width: bodyWidth,
        padding: padding,
        boxSizing: 'border-box',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#534972',
        height: 52,
    },
    footerContainer: {
        minWidth: bodyWidth,
        padding: padding,
        boxSizing: 'border-box',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footerSpan: {
        fontSize: '16px',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(255, 255, 255)',
    },
    pictogram: {
        width: '25%',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'stretch',
        padding: '0',
        boxSizing: 'border-box',
    },
    pictogramCaption: {
        fontSize: '18px',
        fontFamily: "HelveticaNeueCyr",
        color: 'rgb(63, 68, 74)',
        textTransform: 'uppercase',
        textAlign: 'center',
        cursor: 'pointer'
    },
    pictogramImgContainer: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
        // height: '100%',
        // marginTop: 20,
        // marginBottom: 20,
    },
    pictogramImg: {
        maxHeight: '120px',
    },
    wrongPass: {
        backgroundColor: '#d868a7',
        opacity: .85,
        marginLeft: 26,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 15px',
        color: '#fff',
    }
}

