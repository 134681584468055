// @flow
import * as React from 'react'
import { grey } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

import Centred from './centred'

const Loader = ({ children, active }: { children?: React.Node, active: boolean }) => active ? (
  <Centred>
    <CircularProgress style={{ padding: 30, color: grey[500] }} />
  </Centred>
) : children

export default Loader
