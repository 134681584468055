// @flow
import { ServiceApi } from 'constructor'
import axios from 'axios';
import { monitoring } from '../service/endpoints';
import { errorHandler } from '../service/auth';
import { profiles } from './page/profiles/constants';
import { CACHE_NAME } from '../constants/common';
//const CACHE_NAME = process.env.REACT_APP_AUTH_CACHE_NAME

const defaultInstanceConfig = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: process.env.REACT_APP_API_TIMEOUT || 10000,
    headers: {
      'Content-Type': 'application/json',
    }
}

const defaultInstance = axios.create(defaultInstanceConfig);

const getBlobFileInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: process.env.REACT_APP_API_TIMEOUT || 10000,
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Disposition': 'attachment; filename="picture.png"'
    },
    responseType: 'blob',
});
// Temporary instance
const diagnosticInstanceConfig = {
    baseURL: process.env.REACT_APP_DIAG_API_BASE_URL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
}

const diagnosticInstance = axios.create(diagnosticInstanceConfig);

// Profiles instance
const defaultProfileInstance = axios.create(defaultInstanceConfig);
const diagnosticProfileInstance = axios.create(diagnosticInstanceConfig);

const monitorInstance = axios.create({
    baseURL: process.env.REACT_APP_MONITOR_API_BASE_URL,
    timeout: 50000
});

[defaultInstance, diagnosticInstance, monitorInstance, getBlobFileInstance, defaultProfileInstance, diagnosticProfileInstance].forEach(instance => {
  instance.interceptors.request.use(config => {
    const token = localStorage.getItem(CACHE_NAME) && JSON.parse(localStorage.getItem(CACHE_NAME)).access_token
    config.headers.common.Authorization = token ? `Bearer ${ token }` : ''

    return config
  }, (err) => errorHandler(err, instance))
})
defaultInstance.interceptors.response.use((resp) => resp, (error) => errorHandler(error, defaultInstance));
getBlobFileInstance.interceptors.response.use((resp) => resp, (error) => errorHandler(error, getBlobFileInstance));
diagnosticInstance.interceptors.response.use((resp) => resp, (error) => errorHandler(error, diagnosticInstance));
monitorInstance.interceptors.response.use((resp) => resp, (error) => errorHandler(error, monitorInstance));
defaultProfileInstance.interceptors.response.use((resp) => resp, (error) => errorHandler(error, defaultProfileInstance));
diagnosticProfileInstance.interceptors.response.use((resp) => resp, (error) => errorHandler(error, diagnosticProfileInstance));

class ExtendedServiceApi extends ServiceApi {
    constructor(url, instance = defaultProfileInstance) {
        super(url, instance);
        this.instance = instance
    }

    createItem({id, data}) {
        return this.fetcher(`${this.apiUrl}/${id}`, { method: 'POST', data })
    }
}

const ClubService = new ServiceApi('clubs', defaultInstance)
const ViewService = new ServiceApi('view', defaultInstance)
const ViewBannersService = new ServiceApi('view/banners', defaultInstance)
const ViewFoodsService = new ServiceApi('view/food', defaultInstance)
const EventService = new ServiceApi('view', defaultInstance)
const EventsService = new ServiceApi('view/events', defaultInstance)
const MessagesService = new ServiceApi('view/messages', defaultInstance)
const RolesService = new ServiceApi('view/roles', defaultInstance)
const LettersService = new ServiceApi('view/letters', defaultInstance)
const InformationService = new ServiceApi('view/information', defaultInstance)
const ViewChildDayService = new ServiceApi('child-days/child-days-by-group-id', defaultInstance)
const ViewDayService = new ServiceApi('child-days/child-day', defaultInstance)
const CatalogsService = new ServiceApi('catalogs', defaultInstance)
const ContractsService = new ServiceApi('contracts', defaultInstance)
const ContractService = new ServiceApi('contracts', defaultInstance)
const CheckContractService = new ServiceApi('contracts/check-contract-exists', defaultInstance)
const ContractsHoldersService = new ServiceApi('contracts/holders', defaultInstance)
const ChildrenService = new ServiceApi('children/composite', defaultInstance)
const ChildCompositeService = new ServiceApi('children/composite', defaultInstance)
const ChildActualGroupsService = new ServiceApi('children/actual_groups', defaultInstance)
const ChildArchivedGroupsService = new ServiceApi('children/archived_groups', defaultInstance)
const ChildRecommendedGroupsService = new ServiceApi('children/recommended_groups', defaultInstance)
const ChildService = new ServiceApi('children', defaultInstance)
const GroupsService = new ServiceApi('groups/search', defaultInstance)
const GroupService = new ServiceApi('groups', defaultInstance)
const GroupsCompositeService = new ServiceApi('groups/composite', defaultInstance)
const GroupServiceAlone = new ServiceApi('groups/list', defaultInstance)
const GroupLessons = new ServiceApi('groups/for_child', defaultInstance);
const CurrencyCode = new ServiceApi('groups/allowed-currencies', defaultInstance)
const ClubClassService = new ServiceApi('clubClasses', defaultInstance)
const ExtraService = new ServiceApi('extraServices', defaultInstance)
const TrainingTypeService = new ServiceApi('trainingTypes', defaultInstance)
const InfrastructureService = new ServiceApi('infrastructures', defaultInstance)
const ClubFpaccountService = new ServiceApi('clubFpaccounts', defaultInstance)
const ClientService = new ServiceApi('clients', defaultInstance)
const RoomService = new ServiceApi('rooms', defaultInstance)
const AttributeService = new ServiceApi('attributes', defaultInstance)
const AttributeTypeService = new ServiceApi('attributeTypes', defaultInstance)
const UserService = new ServiceApi('users', defaultInstance)
const MailService = new ServiceApi('users/sendmail', defaultInstance)
const CheckUserService = new ServiceApi('users/check-user-exists', defaultInstance)
const MailServiceAllChildren = new ServiceApi('users/children/sendmail', defaultInstance)
const UserConfigService = new ServiceApi('users/resetpassword', defaultInstance)
const UserAccessService = new ServiceApi('users/changestate', defaultInstance)
const UsersService = new ServiceApi('users/search', defaultInstance)
const FileService = new ServiceApi('files', defaultInstance)
const AccountsService = new ServiceApi('organizations/search', defaultInstance)
const AccountService = new ServiceApi('organizations', defaultInstance)
const apiEventsService = new ServiceApi('events', defaultInstance)
const RequisitesService = new ExtendedServiceApi('requisites', defaultInstance)
const DocumentsService = new ServiceApi('documents/accounting_documents', defaultInstance)
const InvoiceService = new ServiceApi('documents/invoice', defaultInstance)
const PaymentService = new ServiceApi('documents/payment', defaultInstance)

//getListExcelFiletInstance
const GetListChildren = new ServiceApi('children/composite', getBlobFileInstance)
const GetListGroups = new ServiceApi('groups/composite', getBlobFileInstance)
const GetListContracts = new ServiceApi('contracts', getBlobFileInstance)
const GetListHolders = new ServiceApi('contracts/holders', getBlobFileInstance)
const GetListStaff = new ServiceApi('users/staff', getBlobFileInstance)
const GetListRequisites = new ServiceApi('requisites', getBlobFileInstance)

// group tabel
const ChildDayField = new ServiceApi('child-days/child-day-field', defaultInstance)

monitorInstance.interceptors.request.use(req => {
    if (req.method === 'post' || req.method === 'patch' || req.method === 'put' || req.method === 'delete' || req.method === 'options') {
        if (req.url[req.url.length-1] !== '/') {
            req.url += '/';
            return req;
        }
        return req;
    }
    return req;
  });

const DiagnosticCompService = new ServiceApi('diag-comp')

const TestService = new ServiceApi('test', diagnosticInstance)
const CatalogService = new ServiceApi('catalog');//, diagnosticInstance)
const ServicePdf = new ServiceApi('diagnostic/pdf/store');//, diagnosticInstance)
const DiagnosticService = new ServiceApi('diagnostic', diagnosticInstance)
const DiagnosticDeleteService = new ServiceApi('diagnostic/delete', diagnosticInstance)
const PpdSphere = new ServiceApi('catalog/ppd-sphere');//, diagnosticInstance)
const PpdSphereCreate = new ServiceApi('catalog/ppd-sphere/create');//, diagnosticInstance)
const PsychoAge = new ServiceApi('catalog/psycho-age');//, diagnosticInstance)
const PpdLine = new ServiceApi('catalog/ppd-line');//, diagnosticInstance)

// MONITOR SERVICES
const MonitorProgramService = new ServiceApi('program', monitorInstance);
const MonitorEducationArea = new ServiceApi('education-area', monitorInstance);
const MonitorActivityType = new ServiceApi('activity-type', monitorInstance);
const MonitorSkills = new ServiceApi('skill', monitorInstance);
const MonitorCriterias = new ServiceApi('criteria', monitorInstance);
const MonitorJournals = id => new ServiceApi(`journals/children/group/${id}/days`, monitorInstance);
//

// TODO: profiles service
/* const setProfileInstance = (url, instance = defaultInstance) => (header) => {
    instance.interceptors.request.use(config => {
        config.headers.common[header] = 'true'
        return config
    })
    return new ServiceApi(url, instance)
} */


let defaultProfileInstanceInterceptor
let diagnosticProfileInstanceInterceptor

class ProfileServiceApi extends ServiceApi {
    constructor(url, instance = defaultProfileInstance) {
        super(url, instance);
        this.instance = instance
        this.defaultApiUrl = this.apiUrl
        //this.interceptor = null
    }

    setHeaders(header) {
        /* this.removeHeaders()
        this.interceptor = this.instance.interceptors.request.use(config => {
            for (const profile of profiles) {
                delete config.headers.common[profile.name]
            }
            config.headers.common[header] = 'true'
            return config
        }) */
        if (this.instance === diagnosticProfileInstance) {
            this.instance.interceptors.request.eject(diagnosticProfileInstanceInterceptor)
            diagnosticProfileInstanceInterceptor = this.instance.interceptors.request.use(config => {
                config.headers.common[header] = 'true'
                return config
            })
        } else {
            this.instance.interceptors.request.eject(defaultProfileInstanceInterceptor)
            defaultProfileInstanceInterceptor = this.instance.interceptors.request.use(config => {
                config.headers.common[header] = 'true'
                return config
            })
        }
        return this
    }
    /* removeHeaders() {
        if (this.interceptor) this.instance.interceptors.request.eject(this.interceptor)
    } */
    create(data) {
        this.apiUrl  = this.defaultApiUrl + '/create/'
        return this.postItem(data)
    }
    update({data}) {
        this.apiUrl = this.defaultApiUrl + '/update/'
        return this.postItem({data})
    }
}
// TODO: удалить когда все сервисы будут переписаны на class ProfileServiceApi
const setProfileInstance = (url, config = defaultInstanceConfig) => (header) => {
    const instance = axios.create({
        ...config,
        headers: {
            ...config.headers,
            [header]: 'true'
        }
    })
    instance.interceptors.request.use(config => {
        const token = localStorage.getItem(CACHE_NAME) && JSON.parse(localStorage.getItem(CACHE_NAME)).access_token
        config.headers.common.Authorization = token ? `Bearer ${ token }` : ''

        return config
      }, (err) => errorHandler(err, instance))
    return new ServiceApi(url, instance)
}

const ProfileService = new ProfileServiceApi('catalogs')
const ProfileTestService = new ProfileServiceApi('test', diagnosticProfileInstance)
const ProfileCatalogService = new ProfileServiceApi('catalog')

const ProfileCompService = new ProfileServiceApi('diag-comp')
const ProfileServicePdf =  new ProfileServiceApi('diagnostic/pdf/store');
const ProfileDiagnosticService = new ProfileServiceApi('diagnostic', diagnosticProfileInstance)
const ProfileDeleteService = new ProfileServiceApi('diagnostic/delete', diagnosticProfileInstance)
const ProfilePpdSphere = new ProfileServiceApi('catalog/ppd-sphere');
const ProfilePpdSphereCUD = new ProfileServiceApi('catalog/ppd-sphere', diagnosticProfileInstance);
const ProfilePsychoClasses = new ProfileServiceApi('catalog/psycho-age');
const ProfilePsychoClassesCUD = new ProfileServiceApi('catalog/psycho-age', diagnosticProfileInstance);
const ProfileParameters = new ProfileServiceApi('catalog/ppd-line');
const ProfileParametersCUD = new ProfileServiceApi('catalog/ppd-line', diagnosticProfileInstance);
const ProfileGroupsCompositeService = new ProfileServiceApi('groups/composite')

// TODO: на удаление
const ProfilePsychoAge = new ProfileServiceApi('catalog/psycho-age');
const ProfilePsychoAgeCUD = new ProfileServiceApi('catalog/psycho-age', diagnosticInstance);
const ProfileDiagnosticCompService = setProfileInstance('diag-comp')
// -------------------------

// const ProfileTwoService = new ServiceApi('catalogs',)
// const ProfileThreeService = new ServiceApi('catalogs',)
// const ProfileFourService = new ServiceApi('catalogs',)

export {
    ContractsService,
    ContractService,
    CheckContractService,
    ContractsHoldersService,
    GroupsService,
    GroupService,
    GroupsCompositeService,
    GroupServiceAlone,
    GroupLessons,
    CurrencyCode,
    ClubService,
    ClubClassService,
    ExtraService,
    InfrastructureService,
    ClubFpaccountService,
    ClientService,
    RoomService,
    AttributeService,
    AttributeTypeService,
    EventService,
    ChildrenService,
    ChildService,
    ChildCompositeService,
    ChildActualGroupsService,
    ChildArchivedGroupsService,
    ChildRecommendedGroupsService,
    RequisitesService,
    DocumentsService,
    InvoiceService,
    PaymentService,

    TrainingTypeService,
    UserService,
    UsersService,
    MailService,
    MailServiceAllChildren,
    UserConfigService,
    UserAccessService,
    CheckUserService,
    FileService,
    AccountService,
    AccountsService,
    CatalogsService,
    ViewService,
    ViewBannersService,
    TestService,
    CatalogService,
    DiagnosticService,
    DiagnosticDeleteService,
    PpdSphere,
    PpdSphereCreate,
    PsychoAge,
    PpdLine,
    DiagnosticCompService,
    ViewFoodsService,
    ViewChildDayService,
    ViewDayService,
    EventsService,
    MessagesService,
    InformationService,
    RolesService,
    LettersService,
    ServicePdf,
    MonitorProgramService,
    MonitorEducationArea,
    MonitorActivityType,
    MonitorSkills,
    MonitorCriterias,
    MonitorJournals,
    monitorInstance,
    apiEventsService,

    GetListChildren,
    GetListGroups,
    GetListContracts,
    GetListHolders,
    GetListStaff,
    GetListRequisites,

    ChildDayField,

    ProfileService,
    ProfileTestService,
    ProfileCatalogService,
    ProfileCompService,
    ProfileServicePdf,
    ProfileDiagnosticService,
    ProfileDeleteService,
    ProfilePpdSphere,
    ProfilePpdSphereCUD,
    ProfilePsychoClasses,
    ProfilePsychoClassesCUD,
    ProfileParameters,
    ProfileParametersCUD,
    ProfileGroupsCompositeService,

    // TODO: на удаление
    ProfilePsychoAge,
    ProfilePsychoAgeCUD,
    ProfileDiagnosticCompService,

}
